<template>
    <div class="app-main__inner">
        <div class="container mb-3">
            <div class="row">
                <div class="col-lg-12 bg-white p-3">
                    <h3 class="text-xl">{{ $t('appSettings')}}</h3>
                    <div class="flex flex-col md:flex-row w-full justify-between align-items-center">
                        <div class="lg:w-1/5 whitespace-nowrap mb-2 font-black md:font-medium">
                            <span v-if="GET_SINGLE_CONNECTION_OBJECT.input_endpoint">{{ GET_SINGLE_CONNECTION_OBJECT.input_endpoint.split('/').join('') + ' - ' + GET_SINGLE_CONNECTION_OBJECT.output_endpoint.split('/').join('') | capitalizeEachString }}</span>
                        </div>
                        <div class="lg:flex lg:flex-row grid grid-cols-2 sm:grid-cols-4  uppercase lg:border lg:border-blue-900 rounded bg-white text-xs md:text-xl overflow-hidden"><!--lg:w-4/5 grid grid-cols-2 sm:grid-cols-5 md:grid-cols-7 lg:grid-cols-9-->
                            <span @click="openTabSection(0)" data-cy='sub-tab0' class="text-xs h-10 border bg-blue-900 lg:border-0 flex justify-center align-items-center cursor-pointer px-2 py-2 whitespace-nowrap" :class="pageTab === 0 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'"><span class="px-2">{{ $t('appSettings')}}</span></span>
                            <span @click="openTabSection(1)" data-cy='sub-tab1' class="text-xs h-10 border bg-blue-900 lg:border-0 flex justify-center align-items-center cursor-pointer px-2 py-2 whitespace-nowrap" :class="pageTab === 1 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{ $t('connectionObject')}}</span>
                            <span @click="openTabSection(2)" data-cy='sub-tab2' class="text-xs h-10 border bg-blue-900 lg:border-0 flex justify-center align-items-center cursor-pointer px-2 py-2 whitespace-nowrap" :class="pageTab === 2 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{ $t('mapFilters')}}</span>
                            <span @click="openTabSection(3)" data-cy='sub-tab2' class="text-xs h-10 border bg-blue-900 lg:border-0 flex justify-center align-items-center cursor-pointer px-2 py-2 whitespace-nowrap" :class="pageTab === 3 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{ $t('filters')}}</span>
                            <span @click="openTabSection(4)" data-cy='sub-tab3' class="text-xs h-10 border bg-blue-900 lg:border-0 flex justify-center align-items-center cursor-pointer px-2 py-2 whitespace-nowrap" :class="pageTab === 4 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{ $t('actions')}}</span>
                            <span @click="openTabSection(5)" data-cy='sub-tab4' class="text-xs h-10 border bg-blue-900 lg:border-0 flex justify-center align-items-center cursor-pointer px-2 py-2 whitespace-nowrap" :class="pageTab === 5 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{ $t('intervals')}}</span>
                            <span @click="openTabSection(6)" data-cy='sub-tab5' class="text-xs h-10 border bg-blue-900 lg:border-0 flex justify-center align-items-center cursor-pointer px-2 py-2 whitespace-nowrap" :class="pageTab === 6 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{ $t('finished') }}</span>
                            <span @click="openTabSection(7)" data-cy='sub-tab5' class="text-xs h-10 border bg-blue-900 lg:border-0 flex justify-center align-items-center cursor-pointer px-2 py-2 whitespace-nowrap" :class="pageTab === 7 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{ $t('syncUrl')}}</span>
                            <span @click="openTabSection(8)" data-cy='sub-tab5' class="text-xs h-10 border bg-blue-900 lg:border-0 flex justify-center align-items-center cursor-pointer px-2 py-2 whitespace-nowrap" :class="pageTab === 8 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{ $t('test')}}</span>
                        </div>
                    </div>
                    <div v-if="pageTab === 0">
                        <div class="mt-20">
                            <div class="row mt-4 mb-4 flex justify-center" v-if="GET_SINGLE_CONNECTION_OBJECT.input_endpoint">
                                <div class="col-md-4">
                                    <div class="flex justify-center">
                                        <div class="h-16 w-1/3">
                                            <img class="img-fluid" :src="GET_SINGLE_CONNECTION_OBJECT.app_image_url" alt="" v-if="GET_SINGLE_CONNECTION_OBJECT.app_image_url">
                                            <span v-else class="whitespace-nowrap">{{ GET_SINGLE_CONNECTION_OBJECT.input_app }}</span>
                                        </div>
                                    </div>
                                    <form @submit.prevent="processInputSetting" v-if="appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.input_app]">
                                        <div class="form-group" v-for="(item, i) in Object.keys(appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.input_app].schema.properties)" :key="i">
                                            <div v-if="appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.input_app].schema.properties[item].type === 'string'">
                                                <label :for="item">{{ item | capitalizeNames }}</label>
                                                <input 
                                                    :value="appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.input_app].values ? appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.input_app].values[item] : ''" 
                                                    :id="'field-'+item" 
                                                    class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full" type="text" 
                                                    :placeholder="item" :required='appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.input_app].schema.required.includes(item)'
                                                    />
                                            </div>
                                            <div v-if="appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.input_app].schema.properties[item].type === 'integer' || appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.input_app].schema.properties[item].type === 'float'">
                                                <label :for="item">{{ item | capitalizeNames }}</label>
                                                <input 
                                                    :value="appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.input_app].values ? appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.input_app].values[item] : ''"  
                                                    :id="'field-'+item" 
                                                    class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full" type="number" :placeholder="item" 
                                                    :required='appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.input_app].schema.required.includes(item)'
                                                    />
                                            </div>
                                            <div v-if="appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.input_app].schema.properties[item].type === 'boolean'">
                                                <label :for="item">{{ item | capitalizeNames }}</label>
                                                <input 
                                                    :value="appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.input_app].values ? appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.input_app].values[item] : ''"  
                                                    :id="'field-'+item" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full" type="checkbox" 
                                                    :placeholder="item" :required='appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.input_app].schema.required.includes(item)'
                                                    />
                                            </div>
                                            <div v-if="appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.input_app].schema.properties[item].type === 'date-time'">
                                                <label :for="item">{{ item | capitalizeNames }}</label>
                                                <input 
                                                    :value="appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.input_app].values ? appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.input_app].values[item] : ''"  
                                                    :id="'field-'+item" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full" type="date" 
                                                    :placeholder="item" :required='appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.input_app].schema.required.includes(item)'
                                                    />
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <button :disabled='updateInputApp' class="c-btn btn-next btn text-sm font-black btn-lg border btn-border-all btn-full-theme text-white hover:text-blue-900" type="submit">
                                                <span v-if="updateInputApp">{{ $t('updating') }}</span>
                                                <span v-else>{{ $t('save') }}</span>
                                            </button>
                                        </div>
                                    </form>
                                    
                                </div>
                                <div class="col-md-1"></div>
                                <div class="col-md-4">
                                    <div class="flex justify-center">
                                        <div class="h-16 w-1/3">
                                            <img class="img-fluid" :src="GET_SINGLE_CONNECTION_OBJECT.app_image_url" alt="" v-if="GET_SINGLE_CONNECTION_OBJECT.app_image_url">
                                            <span v-else class="whitespace-nowrap">{{ GET_SINGLE_CONNECTION_OBJECT.output_app }}</span>
                                        </div>
                                    </div>
                                    
                                    <form @submit.prevent="processOutputSetting" v-if="appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.output_app]">
                                        <div class="form-group" v-for="(item, i) in Object.keys(appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.output_app].schema.properties)" :key="i">
                                            <div v-if="appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.output_app].schema.properties[item].type === 'string'">
                                                <label :for="item">{{ item | capitalizeNames }}</label>
                                                <input 
                                                    :value="appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.output_app].values ? appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.output_app].values[item] : ''" 
                                                    :id="'field-'+item" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full" type="text" 
                                                    :placeholder="item" 
                                                    :required='appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.output_app].schema.required.includes(item)'>
                                            </div>
                                            <div v-if="appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.output_app].schema.properties[item].type === 'integer' || appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.output_app].schema.properties[item].type === 'float'">
                                                <label :for="item">{{ item | capitalizeNames }}</label>
                                                <input 
                                                    :value="appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.output_app].values ? appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.output_app].values[item] : ''" 
                                                    :id="'field-'+item" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full" type="number" 
                                                    :placeholder="item" 
                                                    :required='appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.output_app].schema.required.includes(item)'>
                                            </div>
                                            <div v-if="appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.output_app].schema.properties[item].type === 'boolean'">
                                                <label :for="item">{{ item | capitalizeNames }}</label>
                                                <input 
                                                    :value="appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.output_app].values ? appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.output_app].values[item] : ''" 
                                                    :id="'field-'+item" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full" type="checkbox" 
                                                    :placeholder="item" 
                                                    :required='appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.output_app].schema.required.includes(item)'>
                                            </div>
                                            <div v-if="appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.output_app].schema.properties[item].type === 'date-time'">
                                                <label :for="item">{{ item | capitalizeNames }}</label>
                                                <input 
                                                    :value="appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.output_app].values ? appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.output_app].values[item] : ''" 
                                                    :id="'field-'+item" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full" type="date" 
                                                    :placeholder="item" 
                                                    :required='appSettingsForm[GET_SINGLE_CONNECTION_OBJECT.output_app].schema.required.includes(item)'>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <button :disabled='updateOutputApp' class="c-btn btn-next btn text-sm font-black btn-lg border btn-border-all btn-full-theme text-white hover:text-blue-900" type="submit">
                                                <span v-if="updateOutputApp">{{ $t('updating') }}</span>
                                                <span v-else>{{ $t('save') }}</span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 p-4">
                                <div class="float-right space-x-3">
                                    <!-- <button class="c-btn btn-next-white btn text-sm font-black btn-lg border btn-border-all text-blue-800 hover:text-blue-800" @click="openTab(3)">{{ $t('settingPrevious') }}</button> -->
                                    <button class="c-btn btn-next btn text-sm font-black btn-lg border btn-border-all btn-full-theme text-white hover:text-blue-900" @click="openTabNext">{{ $t('continue') }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="pageTab === 1">
                        <div class="mt-20">
                            <div class="row mt-4 mb-4 flex justify-center" v-if="GET_SINGLE_CONNECTION_OBJECT.input_endpoint">
                                <div class="col-md-4">
                                    <div class="flex justify-center">
                                        <div class="h-16 w-1/3">
                                            <img class="img-fluid" :src="GET_SINGLE_CONNECTION_OBJECT.app_image_url" alt="" v-if="GET_SINGLE_CONNECTION_OBJECT.app_image_url">
                                            <span v-else class="whitespace-nowrap">{{ GET_SINGLE_CONNECTION_OBJECT.input_app }}</span>
                                        </div>
                                    </div>
                                    <div class="form-group flex align-items-center space-x-3">
                                        <select  class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 bg-white py-2 rounded-md text-gray-500 w-full">
                                            <option :value="GET_SINGLE_CONNECTION_OBJECT.input_endpoint.split('/').join('')">{{ GET_SINGLE_CONNECTION_OBJECT.input_endpoint.split('/').join('') | capitalizeNames }}</option>
                                        </select>
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                            </svg>
                                        </span>
                                    </div>
                                    <div class="form-group flex justify-end">
                                        <div class="md:w-60 flex justify-end align-items-center space-x-3 mt-3">
                                            <!-- <select v-model="mapSourceObject" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 bg-white py-2 rounded-md text-gray-500 w-full" @change="selectSourceObject">
                                                <option :value="sourceResponse.endpoint" v-for="(sourceResponse, i) in mapSourceResponse" :key="i">{{ sourceResponse.endpoint | capitalizeNames }}</option>
                                            </select> -->
                                            <span>
                                                <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                                </svg> -->
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-1"></div>
                                <div class="col-md-4">
                                    <div class="flex justify-center">
                                        <div class="h-16 w-1/3">
                                            <img class="img-fluid" :src="GET_SINGLE_CONNECTION_OBJECT.app_image_url" alt="" v-if="GET_SINGLE_CONNECTION_OBJECT.app_image_url">
                                            <span v-else class="whitespace-nowrap">{{ GET_SINGLE_CONNECTION_OBJECT.output_app }}</span>
                                        </div>
                                    </div>
                                    <div class="form-group flex align-items-center space-x-3" >
                                        <select class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full">
                                            <option :value="GET_SINGLE_CONNECTION_OBJECT.output_endpoint.split('/').join('')">{{ GET_SINGLE_CONNECTION_OBJECT.output_endpoint.split('/').join('') | capitalizeNames }}</option>
                                        </select>
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                            </svg>
                                        </span>
                                    </div>
                                    <div class="form-group flex justify-end">
                                        <div class="md:w-60 flex justify-end align-items-center space-x-3 mt-3">
                                            <!-- <select v-model="mapDestinationObject" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 bg-white py-2 rounded-md text-gray-500 w-full" @change="selectDestinationObject">
                                                <option :value="destinationResponse.endpoint" v-for="(destinationResponse, i) in mapDestinationResponse" :key="i">{{ destinationResponse.endpoint | capitalizeNames }}</option>
                                            </select> -->
                                            <span>
                                                <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                                </svg> -->
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 p-4">
                                <div class="float-right space-x-3">
                                    <button class="c-btn btn-next-white btn text-sm font-black btn-lg border btn-border-all text-blue-800 hover:text-blue-800" @click="openTabSection(0)">{{ $t('settingPrevious') }}</button>
                                    <button class="c-btn btn-next btn text-sm font-black btn-lg border btn-border-all btn-full-theme text-white hover:text-blue-900" @click="proceedTab">{{ $t('continue') }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="pageTab === 2">
                        <div class="mt-20">
                            <div class="row mt-4 mb-4 flex justify-center" v-if="GET_SINGLE_CONNECTION_OBJECT.input_endpoint">
                                    <div class="col-md-4 mb-4">
                                        <div class="flex justify-center">
                                            <div class="h-16 w-1/3">
                                                <img class="img-fluid" :src="GET_SINGLE_CONNECTION_OBJECT.app_image_url" alt="" v-if="GET_SINGLE_CONNECTION_OBJECT.app_image_url">
                                                <span v-else class="whitespace-nowrap">{{ GET_SINGLE_CONNECTION_OBJECT.input_app }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group flex align-items-center space-x-3">
                                            <select  class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 bg-white py-2 rounded-md text-gray-500 w-full">
                                                <option :value="GET_SINGLE_CONNECTION_OBJECT.input_endpoint.split('/').join('')">{{ GET_SINGLE_CONNECTION_OBJECT.input_endpoint.split('/').join('') | capitalizeNames }}</option>
                                            </select>
                                            <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                                </svg>
                                            </span>
                                        </div>
                                        <div class="form-group flex justify-end" v-for="(schema, i) in selectedSourceSchema" :key="i">
                                            <div class="w-4/5 lg:w-60 flex justify-end align-items-center space-x-3 mt-3">
                                                <span class="cursor-pointer" @click="removeSourceSchemaValue(i)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-500 font-black" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                                                    </svg>
                                                </span>
                                                <select :value="schema" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 bg-white py-2 rounded-md text-gray-500 w-full" @change="changeFinalSourceValue($event, schema)">
                                                    <option :value="sourceSchema" v-for="(sourceSchema, i) in Object.keys(mapObjectSourceSchema)" :key="i">{{ sourceSchema }}</option>
                                                </select>
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>

                                        <div class="form-group flex justify-end">
                                            <div class="w-1/2 lg:w-60 flex justify-end align-items-center space-x-3 mt-3">
                                                <select v-model="mapSourceValue" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 bg-white py-2 rounded-md text-gray-500 w-full" @change="selectSourceSchema">
                                                    <option :value="sourceSchema" v-for="(sourceSchema, i) in Object.keys(mapObjectSourceSchema)" :key="i">{{ sourceSchema }}</option>
                                                </select>
                                                <span class="lg:w-10">
                                                    <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                                    </svg> -->
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-1"></div>
                                    <div class="col-md-4">
                                        <div class="flex justify-center">
                                            <div class="h-16 w-1/3">
                                                <img class="img-fluid" :src="GET_SINGLE_CONNECTION_OBJECT.app_image_url" alt="" v-if="GET_SINGLE_CONNECTION_OBJECT.app_image_url">
                                                <span v-else class="whitespace-nowrap">{{ GET_SINGLE_CONNECTION_OBJECT.output_app }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group flex align-items-center space-x-3" >
                                            <select class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full">
                                                <option :value="GET_SINGLE_CONNECTION_OBJECT.output_endpoint.split('/').join('')">{{ GET_SINGLE_CONNECTION_OBJECT.output_endpoint.split('/').join('') | capitalizeNames }}</option>
                                            </select>
                                            <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                                </svg>
                                            </span>
                                        </div>
                                        <div class="form-group flex justify-end" v-for="(schema, i) in selectedDestinationSchema" :key="i">
                                            <div class="w-4/5 lg:w-60 flex justify-end align-items-center space-x-3 mt-3">
                                                <span class="cursor-pointer" @click="removeDestinationSchemaValue(i)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-500 font-black" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                                                    </svg>
                                                </span>
                                                <select :value="schema" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 bg-white py-2 rounded-md text-gray-500 w-full" @change="changeFinalDestinationValue($event, schema)">
                                                    <option :value="schemaDestination" v-for="(schemaDestination, i) in Object.keys(mapObjectDestinationSchema)" :key="i">{{ schemaDestination }}</option>
                                                </select>
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>

                                        <div class="form-group flex justify-end">
                                            <div class="w-1/2 lg:w-60 flex justify-end align-items-center space-x-3 mt-3">
                                                <select v-model="mapDestinationValue" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 bg-white py-2 rounded-md text-gray-500 w-full" @change="selectDestinationSchema">
                                                    <option :value="schemaDestination" v-for="(schemaDestination, i) in Object.keys(mapObjectDestinationSchema)" :key="i">{{ schemaDestination }}</option>
                                                </select>
                                                <span class="lg:w-10">
                                                    <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                                    </svg> -->
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 p-4">
                                <div class="float-right space-x-3">
                                    <button class="c-btn btn-next-white btn text-sm font-black btn-lg border btn-border-all text-blue-800 hover:text-blue-800" @click="openTabSection(1)">{{ $t('settingPrevious') }}</button>
                                    <button class="c-btn btn-next btn text-sm font-black btn-lg border btn-border-all btn-full-theme text-white hover:text-blue-900" @click="proceedTabNext">{{ $t('continue') }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col mt-5" v-if="pageTab === 3">
                        <form @submit.prevent="addFilter" class="grid grid-cols-2 md:grid-cols-4 gap-1 w-full align-items-center">
                            <div class="form-group text-xs">
                                <label for="">{{ $t('selectFilters') }}</label>
                                <select v-model="filterForm.allow" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                    <option value="new_id">new_id</option>
                                    <option value="id_is_even">id_is_even</option>
                                    <option value="id_is_odd">id_is_odd</option>
                                    <option value="key_is_even">key_is_even</option>
                                    <option value="key_is_odd">key_is_odd</option>
                                    <option value="key_equal">key_equal</option>
                                    <option value="key_not_equal">key_not_equal</option>
                                    <option value="key_greater">key_greater</option>
                                    <option value="key_greater_or_equal">key_greater_or_equal</option>
                                    <option value="key_less">key_less</option>
                                    <option value="key_less_or_equal">key_less_or_equal</option>
                                    <option value="key_contains">key_contains</option>
                                    <option value="key_is_null">key_is_null</option>
                                    <option value="key_is_empty">key_is_empty</option>
                                    <option value="key_is_not_null">key_is_not_null</option>
                                    <option value="key_is_not_empty">key_is_not_empty</option>
                                </select>
                            </div>
                            <div class="form-group text-xs" v-if="filterForm.allow !== 'new_id' && filterForm.allow !== 'id_is_even' && filterForm.allow !== 'id_is_odd'">
                                <label for="">{{ $t('selectKeys') }}</label>
                                <select v-model='filterForm.select' class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                    <option value="source">{{ $t('source') }}</option>
                                    <option value="destination">{{ $t('destination') }}</option>
                                </select>
                            </div>
                            <div v-if="filterForm.select !== '' && filterForm.allow !== 'new_id' && filterForm.allow !== 'id_is_even' && filterForm.allow !== 'id_is_odd'">
                                <div class="form-group text-xs" v-if="filterForm.select === 'source'">
                                    <label for="">{{ $t('selectKeySource') }}</label>
                                    <select v-model='filterForm.key' class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                        <option :value="key" v-for="(key, i) in Object.keys(mapObjectSourceSchema)" :key="i">{{ key }}</option>
                                    </select>
                                </div>
                                <div class="form-group text-xs" v-if="filterForm.select === 'destination'">
                                    <label for="">{{ $t('selectKeyDestination') }}</label>
                                    <select v-model='filterForm.key' class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                        <option :value="key" v-for="(key, i) in Object.keys(mapObjectDestinationSchema)" :key="i">{{ key }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group text-xs" v-if="filterForm.allow !== 'new_id' && filterForm.allow !== 'id_is_even' && filterForm.allow !== 'id_is_odd'">
                                <label for="">{{ $t('addValue') }}</label>
                                <input v-model='filterForm.value' type="text" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                <!-- <select class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                    <option value="">Object selection</option>
                                </select> -->
                            </div>
                            <div class="form-group text-xs mt-7">
                                <button class="bg-green-500 px-3 py-2 rounded-md focus:outline-none text-white">{{ $t('add') }}</button>
                            </div>
                        </form>
                        <div class="mt-5">
                            <span class="text-base">{{ $t('filters') }}</span>
                            <div class="table-responsive w-full lg:w-3/4">
                                <table style="width: 100%;" id="exampledestroy" class="table table-hover table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th class="text-xs">Allow</th>
                                            <th class="text-xs">Key</th>
                                            <th class="text-xs">Value</th>
                                            <th class="text-xs">From</th>
                                            <th class="text-xs"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="text-xs" v-for="(item, i) in filters" :key="i">
                                            <td>
                                                <span>{{ item.allow }}</span>
                                            </td>
                                            <td>
                                                <span v-if="item.key">{{ item.key }}</span>
                                            </td>
                                            <td>
                                                <span v-if="item.value">{{ item.value }}</span>
                                            </td>
                                            <td>
                                                <span v-if="item.from">{{ item.from }}</span>
                                            </td>
                                            <td>
                                                <span class="cursor-pointer" @click="removeFilter(i)">
                                                    <img class="w-4" src="./../../assets/images/iconly/delete.svg" alt="">
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>  
                        <div class="row">
                            <div class="col-md-12 p-4">
                                <div class="float-right space-x-3">
                                    <button class="c-btn btn-next-white btn text-sm font-black btn-lg border btn-border-all text-blue-800 hover:text-blue-800" @click="openTabSection(2)">{{ $t('settingPrevious') }}</button>
                                    <button class="c-btn btn-next btn text-sm font-black btn-lg border btn-border-all btn-full-theme text-white hover:text-blue-900" @click="proceedActions">{{ $t('continue') }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col mt-5" v-if="pageTab === 4">
                        <form @submit.prevent="addActionRule" class="grid grid-cols-2 md:grid-cols-4 gap-1 w-full align-items-center">
                            <div class="form-group text-xs">
                                <label for="">{{ $t('selectActionType' )}}</label>
                                <select v-model="actionForm.type" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                    <option value="Condition">condition</option>
                                    <option value="Operation">operation</option>
                                    <option value="Transformation">transformation</option>
                                </select>
                            </div>
                            <div class="form-group text-xs" v-if="actionForm.type && actionForm.type !== 'Transformation'">
                                <label for="">{{ $t('selectNameType' )}}</label>
                                <select v-model="actionForm.name" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60" v-if="actionForm.type === 'Condition'" required>
                                    <option value="equal">Equal</option>
                                    <option value="less">Less</option>
                                    <option value="less_than_or_equal">Less Than Or Equal</option>
                                    <option value="greater">Greater</option>
                                    <option value="greater_than_or_equal">Greater Than Or Equal</option>
                                </select>
                                <select v-model="actionForm.name" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60" v-if="actionForm.type === 'Operation'" required>
                                    <option value="add_value_in">Add value in</option>
                                    <option value="add_value_out">Add value out</option>
                                </select>
                            </div>
                            <div class="form-group text-xs" v-if="actionForm.type !== 'Transformation'">
                                <label for="">{{ $t('selectKeys') }}</label>
                                <select v-model="actionForm.select" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                    <option value="source">{{ $t('source') }}</option>
                                    <option value="destination">{{ $t('destination') }}</option>
                                </select>
                            </div>
                            <div v-if="actionForm.select && actionForm.type !== 'Transformation'">
                                <div class="form-group text-xs" v-if="actionForm.select === 'source'">
                                    <label for="">{{ $t('selectKeySource') }}</label>
                                    <select v-model="actionForm.key" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                        <option :value="item" v-for="(item, i) in Object.keys(mapObjectSourceSchema)" :key="i">{{ item }}</option>
                                    </select>
                                </div>
                                <div class="form-group text-xs" v-if="actionForm.select === 'destination'">
                                    <label for="">{{ $t('selectKeyDestination') }}</label>
                                    <select v-model="actionForm.key" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                        <option :value="key" v-for="(key, i) in Object.keys(mapObjectDestinationSchema)" :key="i">{{ key }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group text-xs" v-if="actionForm.type === 'Operation'">
                                <label for="">{{ $t('selectOutput') }}</label>
                                <select v-model="actionForm.output" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                    <option value="source">{{ $t('source') }}</option>
                                    <option value="destination">{{ $t('destination') }}</option>
                                </select>
                            </div>
                            <div v-if="actionForm.output && actionForm.type === 'Operation'">
                                <div class="form-group text-xs" v-if="actionForm.output === 'source' && actionForm.type === 'Operation'">
                                    <label for="">{{ $t('selectOutputSource') }}</label>
                                    <select v-model="actionForm.to" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                        <option :value="key" v-for="(key, i) in Object.keys(mapObjectSourceSchema)" :key="i">{{ key }}</option>
                                    </select>
                                </div>
                                <div class="form-group text-xs" v-if="actionForm.output === 'destination' && actionForm.type === 'Operation'">
                                    <label for="">{{ $t('selectOutputDestination') }}</label>
                                    <select v-model="actionForm.to" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                        <option :value="key" v-for="(key, i) in Object.keys(mapObjectDestinationSchema)" :key="i">{{ key }}</option>
                                    </select>
                                </div>
                            </div>
                            
                            <div class="form-group text-xs" v-if="actionForm.type !== 'Transformation'">
                                <label for="">{{ $t('addValue') }}</label>
                                <input v-model="actionForm.value" type="text" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                            </div>
                            <div class="form-group text-xs mt-7">
                                <button class="bg-green-500 px-3 py-2 rounded-md focus:outline-none text-white">{{ $t('add') }}</button>
                            </div>
                        </form>
                        <div class="mt-5">
                            <span class="text-base">{{ $t('actions') }}</span>
                            <div class="table-responsive w-full lg:w-3/4">
                                <table style="width: 100%;" id="exampledestroy" class="table table-hover table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th class="text-xs">Action Type</th>
                                            <th class="text-xs">Name</th>
                                            <th class="text-xs">Key</th>
                                            <!-- <th class="text-xs">Output</th> -->
                                            <th class="text-xs">To</th>
                                            <th class="text-xs">Value</th>
                                            <th class="text-xs"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="text-xs" v-for="(item, i) in actionRule" :key="i">
                                            <td>
                                                <span>{{ item.type }}</span>
                                            </td>
                                            <td>
                                                <span v-if="item.app_name">{{ item.app_name }}</span>
                                            </td>
                                            <td>
                                                <span v-if="item.key">{{ item.key }}</span>
                                            </td>
                                            <td>
                                                <span v-if="item.to">{{ item.to.key }}</span>
                                            </td>
                                            <td>
                                                <span v-if="item.value">{{ item.value }}</span>
                                            </td>
                                            <td>
                                                <span class="cursor-pointer" @click="removeActionRule(i)">
                                                    <img class="w-4" src="./../../assets/images/iconly/delete.svg" alt="">
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div> 
                        <div class="row">
                            <div class="col-md-12 p-4">
                                <div class="float-right space-x-3">
                                    <button class="c-btn btn-next-white btn text-sm font-black btn-lg border btn-border-all text-blue-800 hover:text-blue-800" @click="openTabSection(3)">{{ $t('settingPrevious') }}</button>
                                    <button class="c-btn btn-next btn text-sm font-black btn-lg border btn-border-all btn-full-theme text-white hover:text-blue-900" @click="proceedIntervals">{{ $t('continue') }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col mt-5" v-if="pageTab === 5">
                        <form @submit.prevent="addTrigger" class="grid grid-cols-2 md:grid-cols-4 gap-1 w-full align-items-center">
                            <div class="form-group text-xs">
                                <label for="">{{ $t('triggerStatus') }}</label>
                                <select v-model="trigger.status" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                    <option :value="false">False</option>
                                    <option :value="true">True</option>
                                </select>
                            </div>
                            <div class="form-group text-xs" v-if="trigger.status">
                                <label for="">{{ $t('triggerForEvery') }}</label>
                                <!-- <input v-model="trigger.every" type="text" min="1" placeholder="number" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60"> -->
                                <select v-model="trigger.every" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                    <option :value="1">1</option>
                                    <option :value="5">5</option>
                                    <option :value="10">10</option>
                                    <option :value="30">30</option>
                                </select>
                            </div>
                            <div class="form-group text-xs" v-if="trigger.status">
                                <label for="">{{ $t('triggerPeriod') }}</label>
                                <select v-model="trigger.period" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                    <!-- <option value="seconds">{{ $t('Seconds') }}</option> -->
                                    <option value="minutes">{{ $t('Minutes') }}</option>
                                    <option value="hours">{{ $t('Hours') }}</option>
                                    <option value="days">{{ $t('Days') }}</option>
                                </select>
                            </div>
                            <div class="form-group text-xs mt-7">
                                <button class="bg-green-500 px-3 py-2 rounded-md focus:outline-none text-white">{{ $t('add') }}</button>
                            </div>
                        </form>
                        <div class="mt-5">
                            <span class="text-base">{{ $t('trigger') }}</span>
                            <div class="table-responsive w-full lg:w-3/4">
                                <table style="width: 100%;" id="exampledestroy" class="table table-hover table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th class="text-xs">{{ $t('triggerStatus') }}</th>
                                            <th class="text-xs">{{ $t('triggerForEvery') }}</th>
                                            <th class="text-xs">{{ $t('triggerPeriod') }}</th>
                                            <th class="text-xs"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="text-xs" v-for="(item, i) in triggerArray" :key="i">
                                            <td>
                                                <span>{{ item.status }}</span>
                                            </td>
                                            <td>
                                                <span v-if="item.every">{{ item.every }}</span>
                                            </td>
                                            <td>
                                                <span v-if="item.period">{{ item.period }}</span>
                                            </td>
                                            <td>
                                                <span class="cursor-pointer" @click="removeTrigger(i)">
                                                    <img class="w-4" src="./../../assets/images/iconly/delete.svg" alt="">
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div> 
                        <div class="row">
                            <div class="col-md-12 p-4">
                                <div class="float-right space-x-3">
                                    <button class="c-btn btn-next-white btn text-sm font-black btn-lg border btn-border-all text-blue-800 hover:text-blue-800" @click="openTabSection(4)">{{ $t('settingPrevious') }}</button>
                                    <button class="c-btn btn-next btn text-sm font-black btn-lg border btn-border-all btn-full-theme text-white hover:text-blue-900" @click="proceedNotification">{{ $t('continue') }}</button>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div class="flex flex-col mt-5" v-if="pageTab === 6">
                        <form @submit.prevent="addNotify" class="grid grid-cols-2 md:grid-cols-4 gap-1 w-full align-items-center">
                            <div class="form-group text-xs">
                                <label for="">{{ $t('selectActionType') }}</label>
                                <select v-model="notify.action" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                    <option :value="item" v-for="(item, i) in notifyActionArray" :key="i">{{ item }}</option>
                                </select>
                            </div>
                            <div class="form-group text-xs">
                                <label for="">{{ $t('selectStatusType') }}</label>
                                <select v-model="notify.type" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                    <option value="on_error">Error</option>
                                    <option value="on_success">Success</option>
                                </select>
                            </div>
                            <div class="form-group text-xs">
                                <label for="">{{ $t('notifyFinishRule') }}</label>
                                <select v-model="notify.notify" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                    <option :value="true">True</option>
                                    <option :value="false">False</option>
                                </select>
                            </div>
                            <div class="form-group text-xs">
                                <label for="">{{ $t('notificationChannel') }}</label>
                                <select v-model="notify.channel" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                    <option value="mail">Via Mail</option>
                                    <option value="slack">Via Slack</option>
                                </select>
                            </div>
                            <div class="form-group text-xs" v-if="notify.channel === 'mail'">
                                <label for="">{{ $t('notificationReceiver') }}</label>
                                <input v-model="notify.receiver" type="text" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                            </div>
                            <div class="form-group text-xs lg:mt-7">
                                <button class="bg-green-500 px-3 py-2 rounded-md focus:outline-none text-white">{{ $t('add') }}</button>
                            </div>
                        </form>
                        <div class="mt-5">
                            <span class="text-base">{{ $t('finished') }}</span>
                            <div class="table-responsive w-full lg:w-3/4">
                                <table style="width: 100%;" id="exampledestroy" class="table table-hover table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th class="text-xs">{{ $t('selectActionType') }}</th>
                                            <th class="text-xs">{{ $t('selectStatusType') }}</th>
                                            <th class="text-xs">{{ $t('notificationReceiver') }}</th>
                                            <th class="text-xs">{{ $t('notificationChannel') }}</th>
                                            <th class="text-xs"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="text-xs" v-for="(item, i) in notificationRule" :key="i">
                                            <td>
                                                <span>{{ item.action }}</span>
                                            </td>
                                            <td>
                                                <span v-if="item.error && item.error.action">Error</span>
                                                <span v-if="item.success && item.success.action">Success</span>
                                            </td>
                                            <td>
                                                <span v-if="item.error && item.error.action">{{ item.error.to }}</span>
                                                <span v-if="item.success && item.success.action">{{ item.success.to }}</span>
                                            </td>
                                            <td>
                                                <span v-if="item.error && item.error.action">{{ item.error.via }}</span>
                                                <span v-if="item.success && item.success.action">{{ item.success.via }}</span>
                                            </td>
                                            <td>
                                                <span class="cursor-pointer" @click="removeNotificationRule(i)">
                                                    <img class="w-4" src="./../../assets/images/iconly/delete.svg" alt="">
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 p-4">
                                <div class="float-right space-x-3">
                                    <button class="c-btn btn-next-white btn text-sm font-black btn-lg border btn-border-all text-blue-800 hover:text-blue-800" @click="openTabSection(5)">{{ $t('settingPrevious') }}</button>
                                    <button class="c-btn btn-next btn text-sm font-black btn-lg border btn-border-all btn-full-theme text-white hover:text-blue-900" @click="proceedSyncURL">{{ $t('continue') }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col mt-5" v-if="pageTab === 7">
                        <form @submit.prevent="addSyncUrl" class="grid grid-cols-2 md:grid-cols-4 gap-1 w-full align-items-center">
                            <div class="form-group text-xs">
                                <label for="">{{ $t('syncWithEndpoint') }}</label>
                                <input v-model="syncURLForm.endpoint" type="text" placeholder="https://" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                            </div>
                            <div class="form-group text-xs">
                                <label for="">{{ $t('syncSuccessOrFailure') }}</label>
                                <select v-model="syncURLForm.successFailed" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                    <option :value="true">True</option>
                                    <option :value="false">False</option>
                                </select>
                            </div>
                            <div class="form-group text-xs mt-7">
                                <button class="bg-green-500 px-3 py-2 rounded-md focus:outline-none text-white">{{ $t('add') }}</button>
                            </div>
                        </form>
                        <div class="mt-5">
                            <span class="text-base">{{ $t('syncUrl') }}</span>
                            <div class="table-responsive w-full lg:w-3/4">
                                <table style="width: 100%;" id="exampledestroy" class="table table-hover table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th class="text-xs">{{ $t('syncWithEndpoint') }}</th>
                                            <th class="text-xs">{{ $t('syncSuccessOrFailure') }}</th>
                                            <th class="text-xs"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="text-xs" v-for="(item, i) in syncURLArray" :key="i">
                                            <td>
                                                <span>{{ item.endpoint }}</span>
                                            </td>
                                            <td>
                                                <span v-if="item.stop_if_fail">{{ item.stop_if_fail }}</span>
                                            </td>
                                            <td>
                                                <span class="cursor-pointer" @click="removeSyncURL(i)">
                                                    <img class="w-4" src="./../../assets/images/iconly/delete.svg" alt="">
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>  
                        <div class="row">
                            <div class="col-md-12 p-4">
                                <div class="float-right space-x-3">
                                    <button class="c-btn btn-next-white btn text-sm font-black btn-lg border btn-border-all text-blue-800 hover:text-blue-800" @click="openTabSection(6)">{{ $t('settingPrevious') }}</button>
                                    <button :disabled="updating" class="c-btn btn-next btn text-sm font-black btn-lg border btn-border-all btn-full-theme text-white hover:text-blue-900" @click="updateRuleDefinition">
                                        <span v-if="updating" class="flex align-items-center space-x-3">
                                            <svg class="animate-spin mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            {{ $t('updating') }}
                                        </span>
                                        <span v-else>{{ $t('update') }}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col mt-5" v-if="pageTab === 8">
                        <div class="row">

                        </div>
                        <div class="row">
                            <div class="col-lg-4">

                                <button @click="performTestSubscription" class="c-btn btn-test test-btn px-12 text-white text-xl py-2 rounded shadow-xl" :disabled='runningTest'> <!--btn btn-custom btn-slider-b btn-wide btn-lg px-5 shadow-2xl-->
                                    <div class="flex items-center m-auto" v-if="runningTest">
                                        <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        {{ $t('testing') }}
                                    </div>
                                    <span v-else>{{ $t('testSend') }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import bus from './../../bus'

    export default {
        name : 'app-settings',
        components : {

        },
        data () {
            return {
                pageTab : 0,
                runningTest : false,
                mapSourceResponse : [],
                mapObjectSourceSchema : {},
                appSettingsForm : {},
                mapDestinationResponse : [],
                mapObjectDestinationSchema : {},
                selectedSourceSchema : [],
                selectedDestinationSchema : [],
                mapSourceValue : '',
                mapDestinationValue : '',
                singleFilters : ['new_id', 'id_is_even', 'id_is_odd'],
                filters : [],
                filterForm : {
                    allow : '',
                    select : '',
                    key : '',
                    value : ''
                },
                actionRule : [],
                actionForm : {
                    type : '',
                    select : '',
                    name : '',
                    key : '',
                    value : '',
                    output : '',
                    to : '',
                },
                triggerArray : [],
                trigger : {
                    status : false,
                    every : '',
                    period : ''
                },
                notificationRule : [],
                notifyActionArray : [
                    'partial_update',
                    'create',
                    'update',
                    'delete',
                    'create_or_update',
                    'create_or_partial_update',
                    'create_if_not_exists',
                ],
                notify : {
                    action : '',
                    type : '',
                    channel : '',
                    receiver : '',
                },
                finishRule : {
                    action : '',
                    error : {
                        action : '',
                        via : '',
                    },
                    success : {
                        action : '',
                        via : ''
                    }
                },
                syncURLArray : [],
                syncURLForm : {
                    endpoint : '',
                    successFailed : ''
                },
                updating : false,
                updateInputApp : false,
                updateOutputApp : false
            }
        },
        computed : {
            ...mapGetters({
                USER_TOKEN : 'auth/USER_TOKEN',
                USER_REFRESH : 'auth/USER_REFRESH',
                GET_SINGLE_CONNECTION_OBJECT : 'customer/GET_SINGLE_CONNECTION_OBJECT',
            })
        },
        mounted () {
            const { id } = this.$route.params
            if (id) {
                this.getAppSettings(id)
                this.getConnectionDetails(id)
            }

            const schemaKeys = window.localStorage.getItem('__updatedSchemaKeys__')



            if (schemaKeys) {
                const schema = JSON.parse(schemaKeys)
                this.selectedSourceSchema = schema.sourceKey
                this.selectedDestinationSchema = schema.destinationKey
            }
            setTimeout(() => {
                this.getAppObject()
            }, 2000)
        },
        methods : {
            openTabSection (number) {
                this.pageTab = number
            },
            selectSourceSchema (e) {
                if (!this.selectedSourceSchema.includes(e.target.value)) {
                    this.selectedSourceSchema.push(e.target.value)
                    // this.mapSourceValue = ''
                }
            },
            selectDestinationSchema (e) {
                if (!this.selectedDestinationSchema.includes(e.target.value)) {
                    this.selectedDestinationSchema.push(e.target.value)
                    this.mapDestinationValue = ''
                }
            },
            changeFinalSourceValue (e, oldVal) {
                const indexOfItem = this.selectedSourceSchema.indexOf(oldVal)
                if (!this.selectedSourceSchema.includes(e.target.value)) {
                    this.$set(this.selectedSourceSchema, indexOfItem, e.target.value)
                } else {
                    this.$set(this.selectedSourceSchema, indexOfItem, oldVal)
                }
            },
            changeFinalDestinationValue (e, oldVal) {
                const indexOfItem = this.selectedDestinationSchema.indexOf(oldVal)
                if (!this.selectedDestinationSchema.includes(e.target.value)) {
                    this.$set(this.selectedDestinationSchema, indexOfItem, e.target.value)
                } else {
                    this.$set(this.selectedDestinationSchema, indexOfItem, oldVal)
                }
            },
            removeSourceSchemaValue (index) {
                this.selectedSourceSchema.splice(index, 1)
            },
            removeDestinationSchemaValue (index) {
                this.selectedDestinationSchema.splice(index, 1)
            },
            openTabNext() {
                this.openTabSection(1)
            },
            proceedTab () {
                // this.getAppObject()
                this.openTabSection(2)
            },
            proceedTabNext () {
                if (this.selectedSourceSchema.length !== this.selectedDestinationSchema.length) {
                    this.$services.helpers.notification('Incomplete mapping values', 'error', this)
                    return false
                } else {
                    const payload = {
                        sourceKey : this.selectedSourceSchema,
                        destinationKey : this.selectedDestinationSchema
                    }
                    window.localStorage.setItem('__updatedSchemaKeys__', JSON.stringify(payload))
                    this.openTabSection(3)
                }
            },
            getAppSettings (data) {
                this.$store.dispatch('customer/getConnectionAppSetting', data)
                .then(res => {
                    this.appSettingsForm = res.data
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                        .then(_ => {
                            this.getAppSettings(data)
                        })
                        .catch(_ => {
                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                            window.Bus.$emit('sign-out')
                        })
                    }
                })
            },
            getConnectionDetails (data) {
                this.$store.dispatch('customer/getConnectionInformation', { id : data })
                .then(_ => {})
                .catch(err => {
                    console.log(err)
                    if (err.response.status === 401) {
                        this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                        .then(_ => {
                            this.getConnectionDetails(data)
                        })
                        .catch(_ => {
                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                            window.Bus.$emit('sign-out')
                        })
                    }
                })
            },
            getAppObject () {
                if (this.GET_SINGLE_CONNECTION_OBJECT && this.GET_SINGLE_CONNECTION_OBJECT.input_endpoint) {
                    const input_app = this.GET_SINGLE_CONNECTION_OBJECT.input_app
                    const output_app = this.GET_SINGLE_CONNECTION_OBJECT.output_app
                    this.getSelectedAppObject(input_app, { type : 'source' })
                    this.getSelectedAppObject(output_app, { type : 'destination' })
                    this.populateSaveData()
                }
            },
            getSelectedAppObject(app, utility = null) {
                if (utility) {
                    const payload = {
                        query : `?app=${app}`
                    }
                    this.$store.dispatch('customer/getSelectedAppObject', payload)
                    .then(res => {
                        const { type } = utility
                        if (type === 'source') {
                            const filter = res.data.data.filter(item => item.endpoint === this.GET_SINGLE_CONNECTION_OBJECT.input_endpoint.split('/').join(''))
                            if (filter.length > 0) {
                                this.mapSourceResponse = filter
                                this.mapObjectSourceSchema = filter[0].schema.properties
                            }
                        } else {
                            const filter = res.data.data.filter(item => item.endpoint === this.GET_SINGLE_CONNECTION_OBJECT.output_endpoint.split('/').join(''))
                            if (filter.length > 0) {
                                this.mapDestinationResponse = filter
                                this.mapObjectDestinationSchema = filter[0].schema.properties
                            }
                        }
                    })
                    .catch(err => {
                        console.log('what is the error:', err)
                        if (err.response.status === 401) {
                            this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                            .then(_ => {
                                this.getSelectedAppObject(app, utility)
                            })
                            .catch(_ => {
                                this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                window.Bus.$emit('sign-out')
                            })
                        }
                    })
                }
            },
            populateSaveData () {
                if (this.GET_SINGLE_CONNECTION_OBJECT.definition) {
                    const source = []
                    const destination = []
                    const objectDefinition = this.GET_SINGLE_CONNECTION_OBJECT.definition
                    // Populate data for actions
                    // console.log('Populating...', objectDefinition)
                    if (objectDefinition && objectDefinition.actions) {
                        objectDefinition.actions.forEach(element => {
                            if (element.type === 'Map') {
                                source.push(element.src_key)
                                destination.push(element.dst_key)
                            } else {
                                // Add actions types to actions array
                                if (element.to) {
                                    this.actionRule.push({
                                        type : element.type,
                                        app_name : element.app_name,
                                        value : element.value,
                                        key : element.key,
                                        to : { type : element.to.type, key : element.to.key }
                                    })
                                } else if (element.app_name && !element.to) {
                                    this.actionRule.push({
                                        type : element.type,
                                        app_name : element.app_name,
                                        value : element.value,
                                        key : element.key,
                                    })
                                } else if (!element.app_name) {
                                    this.actionRule.push({
                                        type : element.type
                                    })
                                }
                            }
                        })

                    }
                    // Populate data for filters
                    if (objectDefinition && objectDefinition.filters) {
                        objectDefinition.filters.forEach(element => {
                            if (this.singleFilters.includes(element.allow)) {
                                this.filters.push({ allow : element.allow })
                            } else {
                                this.filters.push({ 
                                    allow : element.allow,
                                    key : element.key,
                                    value : element.value,
                                    from : 'direct'
                                })
                            }
                        })
                    }
                    // Populate data for synarray
                    if (objectDefinition && objectDefinition.depends) {
                        objectDefinition.depends.forEach(element => {
                            this.syncURLArray.push({
                                endpoint : element.endpoint,
                                stop_if_fail : element.stop_if_fail,
                            })
                            
                        })
                    }
                    // Populate data for finish(notification)
                    if (objectDefinition && objectDefinition.finish) {
                        const payload = {
                            action : ''
                        }
                        payload.action = objectDefinition.finish.do.action
                        if (objectDefinition.finish.on_error.via) {
                            payload.error = {
                                action : '',
                                via : '',
                                to : ''
                            }
                            payload.error.action = objectDefinition.finish.on_error.action ? 'Notify' : null
                            payload.error.via = objectDefinition.finish.on_error.via
                            payload.error.to = objectDefinition.finish.on_error.to
                        }
                        if (objectDefinition.finish.on_success.via) {
                            payload.success = {
                                action : '',
                                via : '',
                                to : ''
                            }
                            payload.success.action = objectDefinition.finish.on_success.action ? 'Notify' : null
                            payload.success.via = objectDefinition.finish.on_success.via
                            payload.success.to = objectDefinition.finish.on_success.to
                        }
                        this.notificationRule.push(payload)
                    }
                    this.selectedSourceSchema = source
                    this.selectedDestinationSchema = destination
                    const payload = {
                        sourceKey : this.selectedSourceSchema,
                        destinationKey : this.selectedDestinationSchema
                    }
                    window.localStorage.setItem('__updatedSchemaKeys__', JSON.stringify(payload))
                }
                if (this.GET_SINGLE_CONNECTION_OBJECT.triggers.length > 0) {
                    this.GET_SINGLE_CONNECTION_OBJECT.triggers.forEach(element => {
                        if (element.definition) {
                            if (element.definition.check.active) {
                                this.triggerArray.push({
                                    status : element.definition.check.active,
                                    every : element.definition.check.every,
                                    period : element.definition.check.period
                                })
                            }
                        }
                    })
                }
            },
            performTestSubscription () {
                if (this.GET_SINGLE_CONNECTION_OBJECT.triggers[0]) {
                    this.runningTest = true
                    const key = this.GET_SINGLE_CONNECTION_OBJECT.triggers[0].key

                    this.$store.dispatch('customer/triggerConnection', key)
                    .then(_ => {
                        this.runningTest = false
                        this.$services.helpers.notification('Connection test perform successfully', 'success', this)
                    })
                    .catch(err => {
                        this.runningTest = false
                        if (err.response.status === 401) {
                            this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                            .then(_ => {
                                self.performTestSubscription()
                            })
                            .catch(_ => {
                                this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                window.Bus.$emit('sign-out')
                            })
                        }
                    })
                }
            },
            processInputSetting () {
                this.updateInputApp = true
                const payload = {}
                Object.keys(this.appSettingsForm[this.GET_SINGLE_CONNECTION_OBJECT.input_app].schema.properties).forEach(element => {
                    payload[element] = document.getElementById(`field-${element}`).value
                })
                this.$store.dispatch('customer/updateAppSettings', { app : this.GET_SINGLE_CONNECTION_OBJECT.input_app, uuid : this.$route.params.id, data : payload })
                .then(_ => {
                    bus.$emit('hide-notify-me', '')
                    this.updateInputApp = false
                    this.$services.helpers.notification('Input settings updated successfully', 'success', this)
                })
                .catch(err => {
                    bus.$emit('hide-notify-me', '')
                    this.updateInputApp = false
                    if (err.response.status === 401) {
                        this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                        .then(_ => {
                            this.processInputSetting()
                        })
                        .catch(_ => {
                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                            window.Bus.$emit('sign-out')
                        })
                    }
                    if (err.response.status === 400) {
                        this.$services.helpers.notification(err.response.data.message, 'error', this)
                    }
                })
            },
            processOutputSetting () {
                this.updateOutputApp = true
                const payload = {}
                Object.keys(this.appSettingsForm[this.GET_SINGLE_CONNECTION_OBJECT.output_app].schema.properties).forEach(element => {
                    payload[element] = document.getElementById(`field-${element}`).value
                })
                this.$store.dispatch('customer/updateAppSettings', { app : this.GET_SINGLE_CONNECTION_OBJECT.output_app, uuid : this.$route.params.id, data : payload })
                .then(res => {
                    bus.$emit('hide-notify-me', '')
                    this.updateOutputApp = false
                    this.$services.helpers.notification('Ouput settings updated successfully', 'success', this)
                })
                .catch(err => {
                    bus.$emit('hide-notify-me', '')
                    this.updateOutputApp = false
                    if (err.response.status === 401) {
                        this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                        .then(_ => {
                            this.processOutputSetting()
                        })
                        .catch(_ => {
                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                            window.Bus.$emit('sign-out')
                        })
                    }
                    if (err.response.status === 400) {
                        this.$services.helpers.notification(err.response.data.message, 'error', this)
                    }
                    
                })
            },
            proceedActions () {
                // if (this.filters.length < 1) {
                //     this.$services.helpers.notification('Please add filter rule', 'error', this)
                // } else {
                //     }
                this.openTabSection(4)
            },
            proceedIntervals () {
                // if (this.actionRule.length < 1) {
                //     this.$services.helpers.notification('Configure action types', 'error', this)
                // } else {
                //     }
                this.openTabSection(5)
            },
            proceedNotification () {
                // if (this.triggerArray.length < 1) {
                //     this.$services.helpers.notification('Configure triggers', 'error', this)
                // } else {
                //     }
                this.openTabSection(6)
            },
            proceedSyncURL () {
                // if (this.notificationRule.length < 1) {
                //     this.$services.helpers.notification('Configure notification', 'error', this)
                // } else {
                //     }
                this.openTabSection(7)
            },
            addFilter () {
                if (this.singleFilters.includes(this.filterForm.allow)) {
                    const data = this.filters.filter(item => item.allow === this.filterForm.allow)
                    if (data.length < 1) {
                        this.filters.push({ allow : this.filterForm.allow })
                        this.filterForm.allow = ''
                    }
                } else {
                    if (this.filterForm.allow !== '' && this.filterForm.key !== '' && this.filterForm.value !== '') {
                        this.filters.push({ 
                            allow : this.filterForm.allow,
                            key : this.filterForm.key,
                            value : this.filterForm.value,
                            from : 'direct'
                        })
                        Object.keys(this.filterForm).forEach(element => {
                            this.filterForm[element] = ''                        
                        })
                    }
                }
            },
            removeFilter (indexOfItem) {
                const data = this.filters.filter(item => this.filters.indexOf(item) !== indexOfItem)
                this.filters = data
            },
            addActionRule () {
                if (this.actionForm.type === 'Condition') {

                    const payload =  {
                        type : this.actionForm.type,
                        app_name : this.actionForm.name,
                        value : this.actionForm.value,
                        key : this.actionForm.key,
                    }
                    this.actionRule.push(payload)
                    Object.keys(this.actionForm).forEach(element => {
                        this.actionForm[element] = ''                        
                    })
                } else {
                    if (this.actionForm.type === 'Transformation') {
                        // Check if transformation exist
                        const filterExistingValue = this.actionRule.filter(item => item.type === this.actionForm.type)
                        if (filterExistingValue.length < 1) {
                            this.actionRule.push({ type : this.actionForm.type })
                        } else {
                            this.$services.helpers.notification('Key value already exist', 'error', this)
                        }
                    } else {
                        this.actionRule.push({
                            type : this.actionForm.type,
                            app_name : this.actionForm.name,
                            value : this.actionForm.value,
                            key : this.actionForm.key,
                            to : { type : 'output_object', key : this.actionForm.to }
                        })
                    }
                    Object.keys(this.actionForm).forEach(element => {
                        this.actionForm[element] = ''                        
                    })
                }
            },
            removeActionRule (indexOfItem) {
                const data = this.actionRule.filter(item => this.actionRule.indexOf(item) !== indexOfItem)
                this.actionRule = data
            },
            addTrigger () {
                if (this.trigger.status === '') {
                    this.$services.helpers.notification('Add trigger actions', 'error', this)
                } else {
                    const payload = {
                        status : this.trigger.status,
                        every : this.trigger.every,
                        period : this.trigger.period
                    }
                    this.triggerArray.push(payload)
                    Object.keys(this.trigger).forEach(element => {
                        this.trigger[element] = ''  
                    })
                }
            },
            removeTrigger (indexOfItem) {
                const data = this.triggerArray.filter(item => this.triggerArray.indexOf(item) !== indexOfItem)
                this.triggerArray = data
            },
            addNotify () {
                if (this.notify.action === '' || this.notify.type === '' || this.notify.channel === '') {
                    this.$services.helpers.notification('Add notification rule', 'error', this)
                } else if (this.notify.channel === 'mail' && !this.$services.helpers.validateEmailAddress(this.notify.receiver)) {
                    this.$services.helpers.notification('Enter valid email receiver', 'error', this)
                } else {
                    const payload = {
                        action : '',
                        error : {
                            action : '',
                            via : '',
                            to : ''
                        },
                        success : {
                            action : '',
                            via : '',
                            to : ''
                        },
                    }
                    payload.action = this.notify.action
                    if (this.notify.type === 'on_error') {
                        payload.error.action = true
                        payload.error.via = this.notify.channel
                        payload.error.to = this.notify.receiver
                    } else {
                        payload.success.action = true
                        payload.success.via = this.notify.channel
                        payload.success.to = this.notify.receiver
                    }
                    
                    this.notificationRule.push(payload)
                    Object.keys(this.notify).forEach(element => {
                        this.notify[element] = ''  
                    })
                }
            },
            removeNotificationRule (indexOfItem) {
                const data = this.notificationRule.filter(item => this.notificationRule.indexOf(item) !== indexOfItem)
                this.notificationRule = data
            },
            addSyncUrl () {
                if (this.syncURLForm.endpoint === '' || this.syncURLForm.successFailed === '') {
                    return
                } else {
                    const payload = {
                        endpoint : this.syncURLForm.endpoint,
                        stop_if_fail : this.syncURLForm.successFailed,
                    }
                    this.syncURLArray.push(payload)
                    Object.keys(this.syncURLForm).forEach(element => {
                        this.syncURLForm[element] = ''  
                    })
                }
            },
            removeSyncURL (indexOfItem) {
                const data = this.syncURLArray.filter(item => this.syncURLArray.indexOf(item) !== indexOfItem)
                this.syncURLArray = data
            },
            updateRuleDefinition () {
                // check for filters
                // if (this.filters.length < 1) {
                //     this.$services.helpers.notification('Please add filter rule', 'error', this)
                // } else if (this.actionRule.length < 1) {
                //     this.$services.helpers.notification('Configure action types', 'error', this)
                // } else if (this.notificationRule.length < 1) {
                //     this.$services.helpers.notification('Configure notification rule', 'error', this)
                // } else if (this.triggerArray.length < 1) {
                //     this.$services.helpers.notification('Invalid trigger configuration', 'error', this)
                // } else {
                    const self = this

                    let payload = {
                        // connection_def : {
                        // },
                        definition : {
                            actions : [],
                            finish : {},
                            depends : [],
                        },
                        triggers : []
                    }
                    const actions = []
                    const finish = {
                        do : {},
                        on_error : {},
                        on_success : {},
                    }

                    // Get respective connection filters and add filter
                    payload.definition.filters = this.filters

                    // Add corresponding schema key actions
                    this.selectedDestinationSchema.forEach((item, i) => {
                        const mapObject = {}
                        mapObject.type = 'Map'
                        mapObject.src_key = item
                        mapObject.dst_key = this.selectedDestinationSchema[i]

                        actions.push(mapObject) // Push to actions array
                    })

                    // Add actions rule
                    this.actionRule.forEach(element => {
                        actions.push(element)
                    })

                    // Push all actions into root object
                    payload.definition.actions = actions

                    // Check for endpoints urls and add to main payload
                    if (this.syncURLArray.length > 0) {
                        payload.definition.depends = this.syncURLArray
                    }

                    // Filter notification rule
                    if (this.notificationRule.length > 0) {
                        // Add on finish rule equivalent -->(Notification rule)
                        // Verify for on_success and on_error notification

                        const filterError = this.notificationRule.filter(item => item.error && item.error.action !== null)
                        const filterSuccess = this.notificationRule.filter(item => item.success && item.success.action !== null)
    

                        // Add for errors
                        if (filterError.length > 0) {
                            finish.do.action = filterError[0].action
                            finish.on_error.action = filterError[0].error.action ? 'Notify' : null
                            finish.on_error.via = filterError[0].error.via || null
                            finish.on_error.to = filterError[0].error.via === 'mail' ? filterError[0].error.to : null
                        }
                        
                        // Add for success
                        if (filterSuccess.length > 0) {
                            finish.do.action = filterSuccess[0].action
                            finish.on_success.action = filterSuccess[0].success.action ? 'Notify' : null
                            finish.on_success.via = filterSuccess[0].success.via || null
                            finish.on_success.to = filterSuccess[0].success.via === 'mail' ? filterSuccess[0].success.to : null
                        }
                    }

                    // Add finish rule to main payload
                    payload['definition']['finish'] = finish

                    // Add on_error constant
                    payload.definition.on_error = 'continue'

                    // Configure trigger equivalent-->(Trigger Array)
                    this.triggerArray.forEach(element => {
                        const data = {
                            definition : { check : {}},
                            webhook : {active: 'True', 'key': 'None'} 
                        }
                        if (element.status) {
                            data.definition.check.active = element.status
                            data.definition.check.every = element.every
                            data.definition.check.period = element.period
                        } else {
                            data.definition.check.active = element.status
                            data.definition.check.every = null
                            data.definition.check.period = null
                        }
                        payload.triggers.push(data)
                    })

                    // Connection uuid
                    const { id } = this.$route.params

                    this.updating = true
                    // Complete all data and submit to backend
                    this.$store.dispatch('customer/addDefinitionRule', { id, payload })
                    .then(_ => {
                        bus.$emit('hide-notify-me', '')
                        this.updating = false
                        this.$services.helpers.notification('Connection updated successfully', 'success', this)
                    })
                    .catch(err => {
                        bus.$emit('hide-notify-me', '')
                        this.updating = false
                        if (err.response.status === 401) {
                            this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                            .then(_ => {
                                self.updateRuleDefinition()
                            })
                            .catch(_ => {
                                this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                window.Bus.$emit('sign-out')
                            })
                        }
                        if (err.response.status === 400) {
                            this.$services.helpers.notification(err.response.data.message, 'error', this)
                        }
                    })
                // }
            },
            switchSubTab (number) {
                this.pageTab = number
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";
</style>
