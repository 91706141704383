<template>
    <div class="app-main__inner">
        <div class="md:flex justify-between align-items-center mb-3 hidden">
            <a class="thumbnail">
                <img class="progress-img" src="./../../assets/images/icon-progress.svg" alt="">
                <div class="caption">
                    <span class="ml-3 text-xs w-full">{{ $t('selectApps') }}</span>
                </div>
            </a>
            <a class="thumbnail">
                <img class="progress-img" src="./../../assets/images/icon-progress.svg" alt="">
                <div class="caption">
                    <p class="ml-3 w-full text-xs">{{ $t('connectApps') }}</p>
                </div>
            </a>
            <a class="thumbnail">
                <img class="progress-img" src="./../../assets/images/icon-progress.svg" alt="">
                <div class="caption">
                    <p class="ml-4 text-xs w-full">{{ $t('mapObjects') }}</p>
                </div>
            </a>
            <a class="thumbnail">
                <img class="progress-img" src="./../../assets/images/icon-progress.svg" alt="">
                <div class="caption">
                    <p class="ml-4 text-xs">{{ $t('mapFields') }}</p>
                </div>
            </a>
            <a class="thumbnail">
                <img class="progress-img" src="./../../assets/images/icon-progress.svg" alt="">
                <div class="caption">
                    <p class="ml-3 text-xs">{{ $t('setRules') }}</p>
                </div>
            </a>
            <a class="thumbnail">
                <img class="progress-img" src="./../../assets/images/icon-progress.svg" alt="">
                <div class="caption">
                    <p class="ml-3 text-xs">{{ $t('testApp') }}</p>
                </div>
            </a>
        </div>     

        <k-progress :percent="percentage" active :show-text="false" :color="color" :line-height="10"></k-progress>
        
        <div class="tab-content mt-4">
            <div class="tab-pane tabs-animation fade active show" v-show="tabId === 1" role="tabpanel">
                <div class="row">
                    <div class="col-md-12">
                        <div class="main-card card">
                            <div class="card-body">
                                <div class="form-group w-1/4">
                                    <input type="text" @input="searchInputTrigger" class="focus:outline-none border rounded-md p-2" :placeholder="$t('searchApp')">
                                </div>
                                <div class="container">
                                    <div class="mb-5" v-if="loading">
                                        <div class="row">
                                            <div class="col-md-2" v-for="i in 60" :key="i">
                                                <p class="h-20 bg-gray-200 rounded-md mb-2"></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3" v-for="app in GET_AVAILABLE_APPS.data" :key="app.id">
                                            <div :class="checkApp(app) ? 'border-2 border-blue-400 rounded' : '' " class="flex flex-col justify-center space-x-4 w-full p-1 cursor-pointer" @click="chooseApp(app)">
                                                <!-- <div class="h-16">
                                                    <img class="h-12" :src="app.app_image_url" alt="" v-if="app.app_image_url">
                                                    <span v-else>{{ app.app_name }}</span>
                                                </div> -->
                                                <div style="min-height:80px" class="text-dark no-deco flex bg-white shadow-sm rounded-lg p-1 v-addapp-3">
                                                    <div class="flex align-items-center items-center space-x-4 w-full p-1">
                                                        <div class="w-16">
                                                            <img class="img-fluid" :src="app.app_image_url" alt="">
                                                        </div>
                                                        <div>{{ app.app_name | removeUnderscoresToken }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 p-4">
                                    <div class="float-right" v-if="showContinue">
                                        <button class="c-btn btn-next btn text-sm w-full font-black btn-lg border btn-border-all btn-full-theme text-white hover:text-white" @click="openSubscription">{{ $t('continue') }}</button> <!-- @click="openTab(2)"-->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane tabs-animation fade active show"  v-show="tabId === 2" role="tabpanel">
                <div class="row">
                    <div class="col-md-12">
                        <div class="main-card card">
                            <div class="card-body">
                                <div class="flex justify-center form-group">
                                    <div class="text-lg">{{ $t('authenticateApps') }}</div>
                                </div>
                                <hr>
                                <div class="row mt-4 mb-4 flex justify-center">
                                    <div class="col-md-4">
                                        <div class="flex justify-center" v-if="selectApp.length > 0">
                                            <div class="h-16 w-1/3">
                                                <img class="h-12" :src="selectApp[0].app_image_url" alt="connected app img" v-if="selectApp[0].app_image_url">
                                                <span class="whitespace-nowrap" v-else>{{ selectApp[0].app_name }}</span>
                                            </div>
                                        </div>
                                        <div v-if="!queryConnection.source.access || !queryConnection.source.complete" class="form-group flex justify-center align-items-center space-x-3">
                                            <button @click="connectSourceApp"  class="px-4 py-2 bg-green-500 rounded-md shadow-md text-white">{{ $t('connect')}}</button>
                                        </div>
                                        <div v-else class="form-group flex justify-center align-items-center space-x-3">
                                            <button disabled class="px-4 py-2 border bg-green-300 cursor-not-allowed text-white rounded-md shadow-xs">{{ $t('connected')}}</button>
                                        </div>
                                    </div>
                                    <div class="col-md-1"></div>
                                    <div class="col-md-4">
                                        <div class="flex justify-center" v-if="selectApp.length > 1">
                                            <div class="h-16 w-1/3">
                                                <img class="h-12" :src="selectApp[1].app_image_url" alt="connected app img" v-if="selectApp[1].app_image_url">
                                                <span class="whitespace-nowrap" v-else>{{ selectApp[1].app_name }}</span>
                                            </div>
                                        </div>
                                        <div v-if="!queryConnection.destination.access || !queryConnection.destination.complete" class="form-group flex justify-center align-items-center space-x-3">
                                            <button @click="connectDestinationApp" class="px-4 py-2 bg-green-500  rounded-md shadow-md text-white">{{ $t('connect') }}</button>
                                        </div>
                                        <div v-else class="form-group flex justify-center align-items-center space-x-3">
                                            <button disabled class="px-4 py-2 border bg-green-300 cursor-not-allowed text-white rounded-md shadow-xs">{{ $t('connected') }}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 p-4">
                                    <div class="float-right space-x-3">
                                        <button v-if="!queryConnection.source.complete && !queryConnection.destination.complete" class="c-btn btn-next-white btn text-sm font-black btn-lg border btn-border-all text-blue-800 hover:text-blue-800" @click="openTab(1)">{{ $t('settingPrevious') }}</button>
                                        <button v-if="queryConnection.source.complete && queryConnection.destination.complete" class="c-btn btn-next btn text-sm font-black btn-lg border btn-border-all btn-full-theme text-white hover:text-blue-900" @click="openTab(3)">{{ $t('continue') }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane tabs-animation fade active show"  v-show="tabId === 3" role="tabpanel">
                <div class="row">
                    <div class="col-md-12">
                        <div class="main-card card">
                            <div class="card-body">
                                <div class="flex justify-center form-group">
                                    <div class="text-lg">{{ $t('selectRelevantApps')}}</div>
                                </div>
                                <hr>
                                <div class="flex justify-center mt-4" v-if="loadingObject">
                                    <svg class="animate-spin h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                </div>
                                <div class="row mt-4 mb-4 flex justify-center" v-else>
                                    <!-- <div class="col-lg-3">
                                        <div class="flex justify-center">
                                            <h3>{{ $t('selectSubscription') }}</h3>
                                        </div>
                                        <div class="mt-12">
                                            <select v-model="userSubscription" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 bg-white py-2 rounded-md text-gray-500 w-full">
                                                <option :value="subscription.id" v-for="subscription in GET_USER_SUBSCRIPTION" :key="subscription.id">{{ subscription.billing_period_unit + ' ('+ subscription.plan_amount + ')' | capitalizeNames }}</option>
                                            </select>
                                        </div>
                                    </div> -->
                                    <div class="col-lg-5">
                                        <div class="flex justify-center" v-if="selectApp.length > 0">
                                            <div class="h-16 w-1/3">
                                                <img class="h-12" :src="selectApp[0].app_image_url" alt="" v-if="selectApp[0].app_image_url">
                                                <span v-else class="whitespace-nowrap">{{ selectApp[0].app_name }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group flex align-items-center space-x-3" v-for="(object, i) in sourceObjectArray" :key="i">
                                            <div class="flex justify-center mt-4 w-full" v-if="!mapSourceResponse.length > 0">
                                                <svg class="animate-spin h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                            </div>
                                            <span class="cursor-pointer" @click="removeMapSourceValue(i)">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-500 font-black" viewBox="0 0 20 20" fill="currentColor">
                                                <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                                                </svg>
                                            </span>
                                            <select v-if="mapSourceResponse.length > 0" :value="object" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 bg-white py-2 rounded-md text-gray-500 w-full" @change="updateChoosenSourceObject($event, object)">
                                                <option :value="sourceResponse.endpoint | removeSlashes" v-for="(sourceResponse, i) in mapSourceResponse" :key="i">{{ sourceResponse.endpoint | capitalizeNames }}</option>
                                            </select>
                                            <span v-if="object && mapSourceResponse.length > 0">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                                </svg>
                                            </span>
                                            <span class="flex justify-end w-20" v-if="object && mapSourceResponse.length > 0">
                                                <svg v-if="directionsHead[i] === 'd'" xmlns="http://www.w3.org/2000/svg"  @click="directionSwitcher(i, 's')" class="h-6 w-6 text-blue-600 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
                                                </svg>
                                                <svg v-if="directionsHead[i] === 's'" xmlns="http://www.w3.org/2000/svg" @click="directionSwitcher(i, 'd')" class="h-6 w-6 text-blue-600 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                                                </svg>
                                            </span>
                                        </div>
                                        <div class="form-group flex justify-end">
                                            <div class="md:w-60 flex justify-end align-items-center space-x-3 mt-3">
                                                <select v-model="mapSourceObject" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 bg-white py-2 rounded-md text-gray-500 w-full" @change="selectSourceObject">
                                                    <option :value="sourceResponse.endpoint | removeSlashes" v-for="(sourceResponse, i) in mapSourceResponse" :key="i">{{ sourceResponse.endpoint | capitalizeNames }}</option>
                                                </select>
                                                <span>
                                                    <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                                    </svg> -->
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-lg-1">
                                        <span class="w-20">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                            </svg>
                                        </span>
                                    </div> -->
                                    <div class="col-lg-4">
                                        <div class="flex justify-center" v-if="selectApp.length > 1">
                                            <div class="h-16 w-1/3">
                                                <img class="h-12" :src="selectApp[1].app_image_url" alt="" v-if="selectApp[1].app_image_url">
                                                <span v-else class="whitespace-nowrap">{{ selectApp[1].app_name }}</span>
                                            </div>
                                            <!-- {{ selectApp[1].app_name }} -->
                                        </div>
                                        <div class="form-group flex align-items-center space-x-3" v-for="(object, i) in destinationObjectArray" :key="i">
                                            <div class="flex justify-center mt-4 w-full" v-if="!mapDestinationResponse.length > 0">
                                                <svg class="animate-spin h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                            </div>
                                            <span class="cursor-pointer" @click="removeMapDestinationValue(i)">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-500 font-black" viewBox="0 0 20 20" fill="currentColor">
                                                <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                                                </svg>
                                            </span>
                                            <select v-if="mapDestinationResponse.length > 0" :id="'select'+i" :value="object" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full" @change="updateChoosenDestinationObject($event, object)">
                                                <option :selected='object' :value="destinationResponse.endpoint | removeSlashes" v-for="(destinationResponse, i) in mapDestinationResponse" :key="i">{{ destinationResponse.endpoint | capitalizeNames }}</option>
                                            </select>
                                            <span v-if="object && mapDestinationResponse.length > 0">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                                </svg>
                                            </span>
                                            
                                        </div>
                                        <div class="form-group flex justify-end">
                                            <div class="md:w-60 flex justify-end align-items-center space-x-3 mt-3">
                                                <select v-model="mapDestinationObject" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 bg-white py-2 rounded-md text-gray-500 w-full" @change="selectDestinationObject">
                                                    <option :value="destinationResponse.endpoint | removeSlashes" v-for="(destinationResponse, i) in mapDestinationResponse" :key="i">{{ destinationResponse.endpoint | capitalizeNames }}</option>
                                                </select>
                                                <span>
                                                    <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                                    </svg> -->
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 p-4">
                                    <div class="float-right space-x-3">
                                        <!-- <button class="c-btn btn-next-white btn text-sm font-black btn-lg border btn-border-all text-blue-800 hover:text-blue-800" @click="openTab(1)">{{ $t('settingPrevious') }}</button> -->
                                        <button :disabled='connecting' v-if="sourceObjectArray.length > 0 && destinationObjectArray.length > 0" class="c-btn btn-next btn text-sm font-black btn-lg border btn-border-all btn-full-theme text-white hover:text-blue-900" @click="proceedMapKeys">
                                            <span v-if="connecting" class="flex justify-between space-x-4">
                                                <svg class="animate-spin h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                                {{ $t('connecting') }}
                                            </span>
                                            <span v-else>{{ $t('initializeConnection') }}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane tabs-animation fade active show"  v-show="tabId === 4" role="tabpanel">
                <div class="row">
                    <div class="col-md-12">
                        <div class="main-card card">
                            <div class="card-body">
                                <div class="flex justify-center form-group">
                                    <div class="text-lg">{{ $t('selectAndMapFields') }}</div>
                                </div>
                                <hr>
                                <div class="flex justify-center mt-4" v-if="loadingObject">
                                    <svg class="animate-spin h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                </div>
                                <div class="row mt-4 mb-4 flex justify-center" v-else>
                                    <div class="col-md-5">
                                        <div class="flex justify-center" v-if="selectApp.length > 0">
                                            <div class="h-16 w-1/3">
                                                <img class="h-12" :src="selectApp[0].app_image_url" alt="" v-if="selectApp[0].app_image_url">
                                                <span class="whitespace-nowrap" v-else>{{ selectApp[0].app_name }}</span>
                                            </div>
                                        </div>

                                        <div v-for="(objectValue, x) in sourceObjectArray" :key="x">
                                            <div class="form-group flex flex-col">
                                                <div class="flex align-items-center space-x-3">
                                                    <select :value="objectValue" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 bg-white py-2 rounded-md text-gray-500 w-full">
                                                        <option :value="objectValue">{{ objectValue }}</option>
                                                    </select>
                                                    <span v-if="objectValue">
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                                        </svg>
                                                    </span>
                                                    <span class="flex justify-end w-20" v-if="objectValue">
                                                        <svg v-if="directionsHead[x] === 'd'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-blue-600 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
                                                        </svg>
                                                        <svg v-if="directionsHead[x] === 's'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-blue-600 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>

                                            <div v-if="finalSourceSchemaKey[x]">
                                                <div class="form-group flex justify-end" v-for="(key, i) in finalSourceSchemaKey[x].keys" :key="i">
                                                    <div class="w-4/5 lg:w-60 flex justify-end align-items-center space-x-3 mt-3">
                                                        <span class="cursor-pointer" @click="removeSourceSchemaValue(i, x)">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-500 font-black" viewBox="0 0 20 20" fill="currentColor">
                                                            <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                                                            </svg>
                                                        </span>
                                                        <select :value="key" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 bg-white py-2 rounded-md text-gray-500 w-full" @change="changeFinalSourceValue($event, key, x)">
                                                            <option :value="schema" v-for="(schema, a) in arrayObjectSource(objectValue)" :key="a">{{ schema }}</option>
                                                        </select>
                                                        <span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                                                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group flex justify-end">
                                                <div class="w-4/5 lg:w-60 flex justify-end align-items-center space-x-3 mt-3">
                                                    <select :value='mapSourceValue' class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 bg-white py-2 rounded-md text-gray-500 w-full" @change="selectSourceSchema($event, x)">
                                                        <option :value="schema" v-for="(schema, i) in arrayObjectSource(objectValue)" :key="i">{{ schema }}</option>
                                                    </select>
                                                    <span class="lg:w-10">
                                                        <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                                        </svg> -->
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-1"></div> -->
                                    <div class="col-md-4">
                                        <div class="flex justify-center" v-if="selectApp.length > 1">
                                            <div class="h-16 w-1/3">
                                                <img class="h-12" :src="selectApp[1].app_image_url" alt="" v-if="selectApp[1].app_image_url">
                                                <span class="whitespace-nowrap" v-else>{{ selectApp[1].app_name }}</span>
                                            </div>
                                        </div>

                                        <div v-for="(objectValue, x) in destinationObjectArray" :key="x">
                                            <div class="form-group flex flex-col">
                                                <div class="flex align-items-center space-x-3">
                                                    <select :value="objectValue" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 bg-white py-2 rounded-md text-gray-500 w-full">
                                                        <option :value="objectValue">{{ objectValue }}</option>
                                                    </select>
                                                    <span v-if="objectValue">
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>

                                            <div v-if="finalDestinationSchemaKey[x]">
                                                <div class="form-group flex justify-end" v-for="(key, i) in finalDestinationSchemaKey[x].keys" :key="i">
                                                    <div class="w-4/5 lg:w-60 flex justify-end align-items-center space-x-3 mt-3">
                                                        <span class="cursor-pointer" @click="removeDestinationSchemaValue(i, x)">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-500 font-black" viewBox="0 0 20 20" fill="currentColor">
                                                            <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                                                            </svg>
                                                        </span>
                                                        <select :value="key" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 bg-white py-2 rounded-md text-gray-500 w-full" @change="changeFinalDestinationValue($event, key, x)">
                                                            <option :value="schema" v-for="(schema, a) in arrayObjectDestination(objectValue)" :key="a">{{ schema }}</option>
                                                        </select>
                                                        <span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                                                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group flex justify-end">
                                                <div class="w-4/5 lg:w-60 flex justify-end align-items-center space-x-3 mt-3">
                                                    <select :value='mapDestinationValue' class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 bg-white py-2 rounded-md text-gray-500 w-full" @change="selectDestinationSchema($event, x)">
                                                        <option :value="schemaDestination" v-for="(schemaDestination, z) in arrayObjectDestination(objectValue)" :key="z">{{ schemaDestination }}</option>
                                                    </select>
                                                    <span class="lg:w-10">
                                                        <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                                        </svg> -->
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>   
                            </div>
                            <div class="row">
                                <div class="col-md-12 p-4">
                                    <div class="float-right space-x-3">
                                        <!-- <button class="c-btn btn-next-white btn text-sm font-black btn-lg border btn-border-all text-blue-800 hover:text-blue-800" @click="openTab(3)">{{ $t('settingPrevious') }}</button> -->
                                        <button class="c-btn btn-next btn text-sm font-black btn-lg border btn-border-all btn-full-theme text-white hover:text-blue-900" @click="proceedLastTab">{{ $t('continue') }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane tabs-animation fade active show"  v-show="tabId === 5" role="tabpanel">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 bg-white rounded-md">
                            <div class="flex flex-col lg:flex-row justify-between align-items-center mt-2">
                                <div class="lg:w-1/5 whitespace-nowrap mb-2">{{ $t('setRulesForSync') }}</div>
                                <div class="lg:flex lg:justify-between lg:align-items-center grid grid-cols-3 sm:grid-cols-4 uppercase lg:border lg:border-blue-900 rounded bg-white text-xs md:text-xl overflow-hidden">
                                    <span data-cy='sub-tab1' @click="switchSubTab(1)" class="text-xs h-10  flex justify-center align-items-center border bg-blue-900 lg:border-0 cursor-pointer px-3 py-2" :class="subTab === 1 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{ $t('filters') }}</span>
                                    <span data-cy='sub-tab2' @click="switchSubTab(2)" class="text-xs h-10  flex justify-center align-items-center border bg-blue-900 lg:border-0 cursor-pointer px-3 py-2" :class="subTab === 2 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{ $t('actions') }}</span>
                                    <span data-cy='sub-tab3' @click="switchSubTab(3)" class="text-xs h-10  flex justify-center align-items-center border bg-blue-900 lg:border-0 cursor-pointer px-3 py-2" :class="subTab === 3 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{ $t('intervals') }}</span>
                                    <span data-cy='sub-tab4' @click="switchSubTab(4)" class="text-xs h-10  flex justify-center align-items-center border bg-blue-900 lg:border-0 cursor-pointer px-3 py-2" :class="subTab === 4 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{ $t('finished') }}</span>
                                    <span data-cy='sub-tab5' @click="switchSubTab(5)" class="text-xs h-10  flex justify-center align-items-center border bg-blue-900 lg:border-0 cursor-pointer px-3 py-2" :class="subTab === 5 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{ $t('syncUrl') }}</span>
                                </div>
                            </div>
                            <div class="flex flex-col mt-5" v-if="subTab === 1">
                                <form @submit.prevent="addFilter" class="grid grid-cols-2 md:grid-cols-4 gap-1 w-full align-items-center">
                                    <div class="form-group text-xs">
                                        <label for="">{{ $t('selectEndpoint') }}</label>
                                        <select v-model="filterObject" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                            <option :value="i" v-for="(object, i) in sourceObjectArray" :key="i">{{ directionsHead[i] === 's' ? object + ' -- ' + destinationObjectArray[i] : destinationObjectArray[i] + ' -- ' +object | capitalizeEachString}}</option>
                                        </select>
                                    </div>
                                    <div class="form-group text-xs">
                                        <label for="">{{ $t('selectFilters') }}</label>
                                        <select v-model="filterForm.allow" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                            <option value="new_id">new_id</option>
                                            <option value="id_is_even">id_is_even</option>
                                            <option value="id_is_odd">id_is_odd</option>
                                            <option value="key_is_even">key_is_even</option>
                                            <option value="key_is_odd">key_is_odd</option>
                                            <option value="key_equal">key_equal</option>
                                            <option value="key_not_equal">key_not_equal</option>
                                            <option value="key_greater">key_greater</option>
                                            <option value="key_greater_or_equal">key_greater_or_equal</option>
                                            <option value="key_less">key_less</option>
                                            <option value="key_less_or_equal">key_less_or_equal</option>
                                            <option value="key_contains">key_contains</option>
                                            <option value="key_is_null">key_is_null</option>
                                            <option value="key_is_empty">key_is_empty</option>
                                            <option value="key_is_not_null">key_is_not_null</option>
                                            <option value="key_is_not_empty">key_is_not_empty</option>
                                        </select>
                                    </div>
                                    <div class="form-group text-xs" v-if="filterForm.allow !== 'new_id' && filterForm.allow !== 'id_is_even' && filterForm.allow !== 'id_is_odd'">
                                        <label for="">{{ $t('selectKeys') }}</label>
                                        <select v-model='filterForm.select' class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                            <option value="source">{{ $t('source') }}</option>
                                            <option value="destination">{{ $t('destination') }}</option>
                                        </select>
                                    </div>
                                    <div v-if="filterForm.select !== '' && filterForm.allow !== 'new_id' && filterForm.allow !== 'id_is_even' && filterForm.allow !== 'id_is_odd'">
                                        <div class="form-group text-xs" v-if="filterForm.select === 'source'">
                                            <label for="">{{ $t('selectKeySource') }}</label>

                                            <select v-if="directionsHead[filterObject] === 's'" v-model='filterForm.key' class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                                <option :value="key" v-for="(key, i) in finalSourceSchemaKey[filterObject].keys" :key="i">{{ key }}</option>
                                            </select>

                                            <select v-else v-model='filterForm.key' class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                                <option :value="key" v-for="(key, i) in finalDestinationSchemaKey[filterObject].keys" :key="i">{{ key }}</option>
                                            </select>
                                        </div>
                                        <div class="form-group text-xs" v-if="filterForm.select === 'destination'">
                                            <label for="">{{ $t('selectKeyDestination') }}</label>
                                            <select v-if="directionsHead[filterObject] === 'd'" v-model='filterForm.key' class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                                <option :value="key" v-for="(key, i) in finalSourceSchemaKey[filterObject].keys" :key="i">{{ key }}</option>
                                            </select>
                                            <select v-else v-model='filterForm.key' class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                                <option :value="key" v-for="(key, i) in finalDestinationSchemaKey[filterObject].keys" :key="i">{{ key }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group text-xs" v-if="filterForm.allow !== 'new_id' && filterForm.allow !== 'id_is_even' && filterForm.allow !== 'id_is_odd'">
                                        <label for="">{{ $t('addValue') }}</label>
                                        <input v-model='filterForm.value' type="text" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                        <!-- <select class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                            <option value="">Object selection</option>
                                        </select> -->
                                    </div>
                                    <div class="form-group text-xs mt-7">
                                        <button class="bg-green-500 px-3 py-2 rounded-md focus:outline-none text-white">{{ $t('add') }}</button>
                                    </div>
                                </form>
                                <div class="mt-5">
                                    <span class="text-base">{{ $t('filters') }}</span>
                                    <div class="table-responsive w-full lg:w-3/4">
                                        <table v-loading='loading' style="width: 100%;" id="exampledestroy" class="table table-hover table-striped table-bordered">
                                            <thead>
                                                <tr>
                                                    <th class="text-xs">Object</th>
                                                    <th class="text-xs">Allow</th>
                                                    <th class="text-xs">Key</th>
                                                    <th class="text-xs">Value</th>
                                                    <th class="text-xs">From</th>
                                                    <th class="text-xs"></th>
                                                </tr>
                                            </thead>
                                            <Container :tag="{value: 'tbody'}" @drop="onDropFilter">
                                                <Draggable :tag="{value: 'tr'}"  class="text-xs" v-for="(item, a) in filters" :key="a">
                                                    <td>
                                                        <span>{{ directionsHead[a] === 's' ? sourceObjectArray[item.obj] + '--' + destinationObjectArray[item.obj] : destinationObjectArray[item.obj] + ' -- ' + sourceObjectArray[item.obj] | capitalizeEachString }}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{ item.allow }}</span>
                                                    </td>
                                                    <td>
                                                        <span v-if="item.key">{{ item.key }}</span>
                                                    </td>
                                                    <td>
                                                        <span v-if="item.value">{{ item.value }}</span>
                                                    </td>
                                                    <td>
                                                        <span v-if="item.from">{{ item.from }}</span>
                                                    </td>
                                                    <td>
                                                        <span class="cursor-pointer" @click="removeFilter(i)">
                                                            <img class="w-4" src="./../../assets/images/iconly/delete.svg" alt="">
                                                        </span>
                                                    </td>
                                                </Draggable>
                                            </Container>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-col mt-5" v-if="subTab === 2">
                                <form @submit.prevent="addActionRule" class="grid grid-cols-2 md:grid-cols-4 gap-1 w-full align-items-center">
                                    <div class="form-group text-xs">
                                        <label for="">{{ $t('selectEndpoint') }}</label>
                                        <select v-model="actionObject" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60" required>
                                            <option :value="i" v-for="(object, i) in sourceObjectArray" :key="i">{{ directionsHead[i] === 's' ? object + ' -- ' + destinationObjectArray[i] : destinationObjectArray[i] + ' -- ' + object | capitalizeEachString}}</option>
                                        </select>
                                    </div>
                                    <div class="form-group text-xs">
                                        <label for="">{{ $t('selectActionType') }}</label>
                                        <select v-model="actionForm.type" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                            <option value="Condition">condition</option>
                                            <option value="Operation">operation</option>
                                            <option value="Transformation">transformation</option>
                                        </select>
                                    </div>
                                    <div class="form-group text-xs" v-if="actionForm.type && actionForm.type !== 'Transformation'">
                                        <label for="">{{ $t('selectNameType') }}</label>
                                        <select v-model="actionForm.name" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60" v-if="actionForm.type === 'Condition'" required>
                                            <option value="equal">Equal</option>
                                            <option value="less">Less</option>
                                            <option value="less_than_or_equal">Less Than Or Equal</option>
                                            <option value="greater">Greater</option>
                                            <option value="greater_than_or_equal">Greater Than Or Equal</option>
                                        </select>
                                        <select v-model="actionForm.name" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60" v-if="actionForm.type === 'Operation'" required>
                                            <option value="add_value_in">Add value in</option>
                                            <option value="add_value_out">Add value out</option>
                                        </select>
                                    </div>
                                    <div class="form-group text-xs" v-if="actionForm.type !== 'Transformation'">
                                        <label for="">{{ $t('selectKeys') }}</label>
                                        <select v-model="actionForm.select" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                            <option value="source">{{ $t('source') }}</option>
                                            <option value="destination">{{ $t('destination') }}</option>
                                        </select>
                                    </div>
                                    <div v-if="actionForm.select && actionForm.type !== 'Transformation'">
                                        <div class="form-group text-xs" v-if="actionForm.select === 'source'">
                                            <label for="">{{ $t('selectKeySource') }}</label>
                                            <select v-if="directionsHead[actionObject] === 's'" v-model="actionForm.key" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                                <option :value="item" v-for="(item, i) in finalSourceSchemaKey[actionObject].keys" :key="i">{{ item }}</option>
                                            </select>
                                            <select v-else v-model="actionForm.key" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                                <option :value="key" v-for="(key, i) in finalDestinationSchemaKey[actionObject].keys" :key="i">{{ key }}</option>
                                            </select>
                                        </div>
                                        <div class="form-group text-xs" v-if="actionForm.select === 'destination'">
                                            <label for="">{{ $t('selectKeyDestination') }}</label>
                                            <select v-if="directionsHead[actionObject] === 'd'" v-model="actionForm.key" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                                <option :value="item" v-for="(item, i) in finalSourceSchemaKey[actionObject].keys" :key="i">{{ item }}</option>
                                            </select>
                                            <select v-else v-model="actionForm.key" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                                <option :value="key" v-for="(key, i) in finalDestinationSchemaKey[actionObject].keys" :key="i">{{ key }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group text-xs" v-if="actionForm.type === 'Operation'">
                                        <label for="">{{ $t('selectOutput') }}</label>
                                        <select v-model="actionForm.output" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                            <option value="source">{{ $t('source') }}</option>
                                            <option value="destination">{{ $t('destination') }}</option>
                                        </select>
                                    </div>
                                    <div v-if="actionForm.output && actionForm.type === 'Operation'">
                                        <div class="form-group text-xs" v-if="actionForm.output === 'source' && actionForm.type === 'Operation'">
                                            <label for="">{{ $t('selectOutputSource') }}</label>
                                            <select v-if="directionsHead[actionObject] === 's'" v-model="actionForm.to" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                                <option :value="key" v-for="(key, i) in finalSourceSchemaKey[actionObject].keys" :key="i">{{ key }}</option>
                                            </select>
                                            <select v-else v-model="actionForm.to" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                                <option :value="key" v-for="(key, i) in finalDestinationSchemaKey[actionObject].keys" :key="i">{{ key }}</option>
                                            </select>
                                        </div>
                                        <div class="form-group text-xs" v-if="actionForm.output === 'destination' && actionForm.type === 'Operation'">
                                            <label for="">{{ $t('selectOutputDestination') }}</label>
                                            <select v-if="directionsHead[actionObject] === 'd'" v-model="actionForm.to" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                                <option :value="item" v-for="(item, i) in finalSourceSchemaKey[actionObject].keys" :key="i">{{ item }}</option>
                                            </select>
                                            <select v-else v-model="actionForm.to" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                                <option :value="key" v-for="(key, i) in finalDestinationSchemaKey[actionObject].keys" :key="i">{{ key }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    
                                    <div class="form-group text-xs" v-if="actionForm.type !== 'Transformation'">
                                        <label for="">{{ $t('addValue') }}</label>
                                        <input v-model="actionForm.value" type="text" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                    </div>
                                    <div class="form-group text-xs lg:mt-7">
                                        <button class="bg-green-500 px-3 py-2 rounded-md focus:outline-none text-white">{{ $t('add') }}</button>
                                    </div>
                                </form>
                                <div class="mt-5">
                                    <span class="text-base">{{ $t('actions') }}</span>
                                    <div class="table-responsive w-full lg:w-3/4">
                                        <table v-loading='loading' style="width: 100%;" id="exampledestroy" class="table table-hover table-striped table-bordered">
                                            <thead>
                                                <tr>
                                                    <th class="text-xs">Object</th>
                                                    <th class="text-xs">Action Type</th>
                                                    <th class="text-xs">Name</th>
                                                    <th class="text-xs">Key</th>
                                                    <th class="text-xs">To</th>
                                                    <th class="text-xs">Value</th>
                                                    <th class="text-xs"></th>
                                                </tr>
                                            </thead>
                                            <Container :tag="{value: 'tbody'}" @drop="onDropAction">
                                                <Draggable :tag="{value: 'tr'}" class="text-xs" v-for="(item, i) in actionRule" :key="i">
                                                    <td>
                                                        <span>{{ directionsHead[i] === 's' ? sourceObjectArray[item.obj] + '--' + destinationObjectArray[item.obj] : destinationObjectArray[item.obj] + ' -- ' + sourceObjectArray[item.obj] | capitalizeEachString }}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{ item.type }}</span>
                                                    </td>
                                                    <td>
                                                        <span v-if="item.app_name">{{ item.app_name }}</span>
                                                    </td>
                                                    <td>
                                                        <span v-if="item.key">{{ item.key }}</span>
                                                    </td>
                                                    <td>
                                                        <span v-if="item.to">{{ item.to.key }}</span>
                                                    </td>
                                                    <td>
                                                        <span v-if="item.value">{{ item.value }}</span>
                                                    </td>
                                                    <td>
                                                        <span class="cursor-pointer" @click="removeActionRule(i)">
                                                            <img class="w-4" src="./../../assets/images/iconly/delete.svg" alt="">
                                                        </span>
                                                    </td>
                                                </Draggable>
                                            </Container>
                                        </table>
                                    </div>
                                </div>  
                                <!-- <div class="col-md-12 p-4">
                                    <div class="float-right space-x-3">
                                        <button class="c-btn btn-next btn text-sm font-black btn-lg border btn-border-all btn-full-theme text-white hover:text-blue-900" @click="switchSubTab(3)">{{ $t('continue') }}</button>
                                    </div>
                                </div> -->
                            </div>
                            <div class="flex flex-col mt-5" v-if="subTab === 3">
                                <form @submit.prevent="addTrigger" class="grid grid-cols-2 md:grid-cols-4 gap-1 w-full align-items-center">
                                    <div class="form-group text-xs">
                                        <label for="">{{ $t('selectEndpoint') }}t</label>
                                        <select v-model="intervalObject" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60" required>
                                            <option :value="i" v-for="(object, i) in sourceObjectArray" :key="i">{{ directionsHead[i] === 's' ? object + ' -- ' + destinationObjectArray[i] : destinationObjectArray[i] + ' -- ' + object | capitalizeEachString}}</option>
                                        </select>
                                    </div>
                                    <div class="form-group text-xs">
                                        <label for="">{{ $t('triggerStatus') }}</label>
                                        <select v-model="trigger.status" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                            <option :value="false">False</option>
                                            <option :value="true">True</option>
                                        </select>
                                    </div>
                                    <div class="form-group text-xs" v-if="trigger.status">
                                        <label for="">{{ $t('triggerForEvery') }}</label>
                                        <!-- <input  type="text" min="1" placeholder="number" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60"> -->
                                        <select v-model="trigger.every" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                            <option :value="1">1</option>
                                            <option :value="5">5</option>
                                            <option :value="10">10</option>
                                            <option :value="30">30</option>
                                        </select>
                                    </div>
                                    <div class="form-group text-xs" v-if="trigger.status">
                                        <label for="">{{ $t('triggerPeriod') }}</label>
                                        <select v-model="trigger.period" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                            <!-- <option value="seconds">{{ $t('Seconds') }}</option> -->
                                            <option value="minutes">{{ $t('Minutes') }}</option>
                                            <option value="hours">{{ $t('Hours') }}</option>
                                            <option value="days">{{ $t('Days') }}</option>
                                            <!-- <option value="">Object selection</option> -->
                                        </select>
                                    </div>
                                    <div class="form-group text-xs lg:mt-7">
                                        <button class="bg-green-500 px-3 py-2 rounded-md focus:outline-none text-white">{{ $t('add') }}</button>
                                    </div>
                                </form>
                                <div class="mt-5">
                                    <span class="text-base">{{ $t('trigger') }}</span>
                                    <div class="table-responsive w-full lg:w-3/4">
                                        <table v-loading='loading' style="width: 100%;" id="exampledestroy" class="table table-hover table-striped table-bordered">
                                            <thead>
                                                <tr>
                                                    <th class="text-xs">Object</th>
                                                    <th class="text-xs">{{ $t('triggerStatus') }}</th>
                                                    <th class="text-xs">{{ $t('triggerForEvery') }}</th>
                                                    <th class="text-xs">{{ $t('triggerPeriod') }}</th>
                                                    <th class="text-xs"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="text-xs" v-for="(item, i) in triggerArray" :key="i">
                                                    <td>
                                                        <span>{{ directionsHead[i] === 's' ? sourceObjectArray[item.obj] + '--' + destinationObjectArray[item.obj] : destinationObjectArray[item.obj] + ' -- ' + sourceObjectArray[item.obj] | capitalizeEachString }}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{ item.status }}</span>
                                                    </td>
                                                    <td>
                                                        <span v-if="item.every">{{ item.every }}</span>
                                                    </td>
                                                    <td>
                                                        <span v-if="item.period">{{ item.period }}</span>
                                                    </td>
                                                    <td>
                                                        <span class="cursor-pointer" @click="removeTrigger(i)">
                                                            <img class="w-4" src="./../../assets/images/iconly/delete.svg" alt="">
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>  
                                <!-- <div class="col-md-12 p-4">
                                    <div class="float-right space-x-3">
                                        <button class="c-btn btn-next btn text-sm font-black btn-lg border btn-border-all btn-full-theme text-white hover:text-blue-900" @click="switchSubTab(4)">{{ $t('continue') }}</button>
                                    </div>
                                </div> -->
                            </div>
                            <div class="flex flex-col mt-5" v-if="subTab === 4">
                                <form @submit.prevent="addNotify" class="grid grid-cols-2 md:grid-cols-4 gap-1 w-full align-items-center">
                                    <div class="form-group text-xs">
                                        <label for="">{{ $t('selectEndpoint') }}</label>
                                        <select v-model="notifyObject" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                            <option :value="i" v-for="(object, i) in sourceObjectArray" :key="i">{{ directionsHead[i] === 's' ? object + ' -- ' + destinationObjectArray[i] : destinationObjectArray[i] + ' -- ' + object | capitalizeEachString }}</option>
                                        </select>
                                    </div>
                                    <div class="form-group text-xs">
                                        <label for="">{{ $t('selectActionType') }}</label>
                                        <select v-model="notify.action" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                            <option :value="item" v-for="(item, i) in notifyActionArray" :key="i">{{ item }}</option>
                                        </select>
                                    </div>
                                    <div class="form-group text-xs">
                                        <label for="">{{ $t('selectStatusType') }}</label>
                                        <select v-model="notify.type" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                            <option value="on_error">Error</option>
                                            <option value="on_success">Success</option>
                                        </select>
                                    </div>
                                    <div class="form-group text-xs">
                                        <label for="">{{ $t('notifyFinishRule') }}</label>
                                        <select v-model="notify.notify" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                            <option :value="true">True</option>
                                            <option :value="false">False</option>
                                        </select>
                                    </div>
                                    <div class="form-group text-xs">
                                        <label for="">{{ $t('notificationChannel') }}</label>
                                        <select v-model="notify.channel" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60" required>
                                            <option value="mail">Via Mail</option>
                                            <option value="slack">Via Slack</option>
                                        </select>
                                    </div>
                                    <div class="form-group text-xs" v-if="notify.channel === 'mail'">
                                        <label for="">{{ $t('notificationReceiver') }}</label>
                                        <input v-model="notify.receiver" type="text" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60" required>
                                    </div>
                                    <div class="form-group text-xs mt-7">
                                        <button class="bg-green-500 px-3 py-2 rounded-md focus:outline-none text-white">{{ $t('add') }}</button>
                                    </div>
                                </form>
                                <div class="mt-5">
                                    <span class="text-base">{{ $t('finished') }}</span>
                                    <div class="table-responsive w-full lg:w-3/4">
                                        <table v-loading='loading' style="width: 100%;" id="exampledestroy" class="table table-hover table-striped table-bordered">
                                            <thead>
                                                <tr>
                                                    <th class="text-xs">Object</th>
                                                    <th class="text-xs">Action Type</th>
                                                    <th class="text-xs">Status Type</th>
                                                    <th class="text-xs">Notify</th>
                                                    <th class="text-xs">Receiver</th>
                                                    <th class="text-xs">Channel</th>
                                                    <th class="text-xs"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="text-xs" v-for="(item, i) in notificationRule" :key="i">
                                                    <td>
                                                        <span>{{ directionsHead[i] === 's' ? sourceObjectArray[item.obj] + '--' + destinationObjectArray[item.obj] : destinationObjectArray[item.obj] + ' -- '+ sourceObjectArray[item.obj] | capitalizeEachString }}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{ item.action }}</span>
                                                    </td>
                                                    <td>
                                                        <span v-if="item.error.via">Error</span>
                                                        <span v-if="item.success.via">Success</span>
                                                    </td>
                                                    <td>
                                                        <span v-if="item.error.action === 'notify'">Yes</span>
                                                        <span v-else-if="item.success.action === 'notify'">Yes</span>
                                                        <span v-else>No</span>
                                                    </td>
                                                    <td>
                                                        <span v-if="item.error.to">{{ item.error.to }}</span>
                                                        <span v-if="item.success.to">{{ item.success.to }}</span>
                                                    </td>
                                                    <td>
                                                        <span v-if="item.error.via">{{ item.error.via }}</span>
                                                        <span v-if="item.success.via">{{ item.success.via }}</span>
                                                    </td>
                                                    <td>
                                                        <span class="cursor-pointer" @click="removeNotificationRule(i)">
                                                            <img class="w-4" src="./../../assets/images/iconly/delete.svg" alt="">
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>  
                            </div>
                            <div class="flex flex-col mt-5" v-if="subTab === 5">
                                <form @submit.prevent="addSyncUrl" class="grid grid-cols-2 md:grid-cols-4 gap-1 w-full align-items-center">
                                    <div class="form-group text-xs">
                                        <label for="">{{ $t('selectEndpoint') }}</label>
                                        <select v-model="syncObject" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                            <option :value="i" v-for="(object, i) in sourceObjectArray" :key="i">{{ directionsHead[i] === 's' ? object + ' -- ' + destinationObjectArray[i] : destinationObjectArray[i] + ' -- ' + object | capitalizeEachString}}</option>
                                        </select>
                                    </div>
                                    <div class="form-group text-xs">
                                        <label for="">{{ $t('syncWithEndpoint') }}</label>
                                        <input v-model="syncURLForm.endpoint" type="text" placeholder="https://" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                    </div>
                                    <div class="form-group text-xs">
                                        <label for="">{{ $t('syncSuccessOrFailure') }}</label>
                                        <select v-model="syncURLForm.successFailed" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 bg-white w-full lg:w-60">
                                            <option :value="true">True</option>
                                            <option :value="false">False</option>
                                        </select>
                                    </div>
                                    <div class="form-group text-xs mt-7">
                                        <button class="bg-green-500 px-3 py-2 rounded-md focus:outline-none text-white">{{ $t('add') }}</button>
                                    </div>
                                </form>
                                <div class="mt-5">
                                    <span class="text-base">{{ $t('syncUrl') }}</span>
                                    <div class="table-responsive w-full lg:w-3/4">
                                        <table v-loading='loading' style="width: 100%;" id="exampledestroy" class="table table-hover table-striped table-bordered">
                                            <thead>
                                                <tr>
                                                    <th class="text-xs">Object</th>
                                                    <th class="text-xs">{{ $t('syncWithEndpoint') }}</th>
                                                    <th class="text-xs">{{ $t('syncSuccessOrFailure') }}</th>
                                                    <th class="text-xs"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="text-xs" v-for="(item, i) in syncURLArray" :key="i">
                                                    <td>
                                                        <span>{{ directionsHead[i] === 's' ? sourceObjectArray[item.obj] + '--' + destinationObjectArray[item.obj] : destinationObjectArray[item.obj] + ' -- '+ sourceObjectArray[item.obj] | capitalizeEachString }}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{ item.endpoint }}</span>
                                                    </td>
                                                    <td>
                                                        <span v-if="item.stop_if_fail">{{ item.stop_if_fail }}</span>
                                                    </td>
                                                    <td>
                                                        <span class="cursor-pointer" @click="removeSyncURL(i)">
                                                            <img class="w-4" src="./../../assets/images/iconly/delete.svg" alt="">
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>  
                                <div class="col-md-12 p-4">
                                    <div class="float-right space-x-3">
                                        <!-- <button class="c-btn btn-next-white btn text-sm font-black btn-lg border btn-border-all text-blue-800 hover:text-blue-800" @click="openTab(2)">{{ $t('settingPrevious') }}</button> -->
                                        <!-- <button class="c-btn btn-next btn text-sm font-black btn-lg border btn-border-all btn-full-theme text-white hover:text-blue-900">{{ $t('complete') }}</button> -->
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12 p-4">
                                    <div class="float-right space-x-3">
                                        <button class="c-btn btn-next-white btn text-sm font-black btn-lg border btn-border-all text-blue-800 hover:text-blue-800" @click="openTab(4)">{{ $t('settingPrevious') }}</button>
                                        <button :disabled="creating" class="c-btn btn-next btn text-sm font-black btn-lg border btn-border-all btn-full-theme text-white hover:text-blue-900" @click="createRuleDefinition">
                                            <span v-if="creating" class="flex align-items-center space-x-3">
                                                <svg class="animate-spin mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                                {{ $t('creating') }}
                                            </span>
                                            <span v-else>{{ $t('complete') }}</span>
                                        </button>
                                        <button class="c-btn btn-test btn text-sm font-black btn-lg border btn-border-all btn-full-theme text-white hover:text-blue-900" v-if="triggerKeys.length > 0" @click="proceedTestOrder()">{{ $t('testOrder') }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane tabs-animation fade active show"  v-show="tabId === 6" role="tabpanel">
                <div class="row">
                    <div class="col-md-12">
                        <div class="main-card card">
                            <div class="card-body">
                                <div class="row border-b border-gray-200">
                                    <div class="col-md-4">
                                        <h5 class="card-title hidden md:block">{{ $t('testOrder') }}</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 mt-1 ml-2">
                                    <h5 class="card-title block md:hidden">{{ $t('testOrder') }}</h5>
                                    <div v-for="item in GET_APP_CONNECTION" :key="item.unique" class="flex justify-between align-items-center space-x-5">
                                        <div>{{ item.connectionName }}</div>
                                        <div class="flex align-items-center space-x-3">
                                            <span v-if="runningTest">
                                                <svg class="animate-spin mr-2 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                            </span>
                                            <div v-else>
                                                <span v-if="item.complete && !item.failed">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                                    </svg>
                                                </span>
                                                <span v-if="!item.complete && item.failed">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-500" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <button @click="performTestSubscription" class="c-btn btn-test test-btn px-12 text-white text-xl py-2 rounded shadow-xl" :disabled='runningTest'> <!--btn btn-custom btn-slider-b btn-wide btn-lg px-5 shadow-2xl-->
                                        <div class="flex items-center m-auto" v-if="runningTest">
                                            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            {{ $t('testing') }}
                                        </div>
                                        <span v-else>{{ $t('testSend') }}</span>
                                    </button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 p-4">
                                    <div class="float-right space-x-3">
                                        <button class="c-btn btn-next-white btn text-sm font-black btn-lg border btn-border-all text-blue-800 hover:text-blue-800" @click="openTab(5)">{{ $t('settingPrevious') }}</button>
                                        <button class="c-btn btn-next btn text-sm font-black btn-lg border btn-border-all btn-full-theme text-white hover:text-blue-900" @click="clearData">
                                            <span>{{ $t('complete') }}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import { Container, Draggable } from 'vue-smooth-dnd'
    import bus from './../../bus'
    import KProgress from 'k-progress'
    import debounce from 'lodash/debounce'

    export default {
        name : 'app-settings',
        components : {
            'k-progress' : KProgress,
            Container,
            Draggable
        },
        data () {
            return {
                tabId : 1,
                subTab : 1,
                percentage : 1,
                color : '#13c2c2',
                connecting : false,
                directionsHead : [],
                showContinue : false,
                loadingObject : false,
                runningTest : false,
                count : 0,
                loading : true,
                creating : false,
                selectApp : [],
                triggerKeys : [],
                proceedAuthBtn : false,
                userSubscription : '',
                mapObjectSource : [],
                connectionUUID : [],
                mapObjectSourceSchema : {},
                mapObjectDestination : [],
                mapObjectDestinationSchema : {},
                mapSourceResponse : [],
                mapDestinationResponse : [],
                connectionMade : [],
                refresh : false,
                connectionAppURL : [],
                authConnectionURL : {},
                queryConnection : {
                    source : { access : false, complete : false },
                    destination : { access : false, complete : false }
                },
                mapSourceObject : '',
                mapDestinationObject : '',
                sourceObjectArray : [],
                destinationObjectArray : [],
                finalSourceSchemaKey : [],
                finalDestinationSchemaKey : [],
                filterObject : '',
                actionObject : '',
                intervalObject : '',
                notifyObject : '',
                syncObject : '',
                choosenEndpoints : {
                    source : '',
                    destination : ''
                },
                mapSourceValue : '',
                mapDestinationValue : '',
                selectedSourceValue : '',
                selectedDestinationValue : '',
                showAuthFlow : false,
                filters : [],
                singleFilters : ['new_id', 'id_is_even', 'id_is_odd'],
                filterForm : {
                    allow : '',
                    select : '',
                    key : '',
                    value : ''
                },
                actionRule : [],
                actionForm : {
                    type : '',
                    select : '',
                    name : '',
                    key : '',
                    value : '',
                    output : '',
                    to : '',
                },
                triggerArray : [],
                trigger : {
                    status : false,
                    every : '',
                    period : ''
                },
                notificationRule : [],
                notifyActionArray : [
                    'partial_update',
                    'create',
                    'update',
                    'delete',
                    'create_or_update',
                    'create_or_partial_update',
                    'create_if_not_exists',
                ],
                notify : {
                    action : '',
                    type : '',
                    notify : '',
                    channel : '',
                    receiver : '',
                },
                finishRule : {
                    action : '',
                    error : {
                        action : '',
                        via : '',
                    },
                    success : {
                        action : '',
                        via : ''
                    }
                },
                syncURLArray : [],
                syncURLForm : {
                    endpoint : '',
                    successFailed : ''
                },
            }    
        },
        computed : {
            ...mapGetters({
                GET_AVAILABLE_APPS : 'customer/GET_AVAILABLE_APPS',
                GET_CONNECTION_OBJECT : 'customer/GET_CONNECTION_OBJECT',
                USER_REFRESH : 'auth/USER_REFRESH',
                GET_USER : 'customer/GET_USER',
                GET_USER_SUBSCRIPTION : 'customer/GET_USER_SUBSCRIPTION',
                GET_APP_CONNECTION : 'customer/GET_APP_CONNECTION',
                GET_COMPLETE_SUBMISSION : 'customer/GET_COMPLETE_SUBMISSION',
                GET_CONNECTION_MADE : 'customer/GET_CONNECTION_MADE'
            })
        },
        watch : {      
            selectApp : function (n, o) {
                if (this.selectApp.length > 0 && this.selectApp.length === 2) {
                    // if (parseInt(this.tabId) === 1 && this.userSubscription === '') {
                    //     alert('here')
                    //     bus.$emit('select-subscription')
                    // }
                    this.showContinue = true
                } else {
                    this.showContinue = false
                }
            },
            tabId : function (newVal, oldVal) {
                if (parseInt(newVal) === 2 || parseInt(newVal) === 4) {    
                    this.loadingObject = true
                    this.selectApp = JSON.parse(window.localStorage.getItem('__selectApp'))
                    if (this.selectApp.length > 1) {
                        this.getSelectedAppObject(this.selectApp[0], { type : 'source' })
                        this.getSelectedAppObject(this.selectApp[1], { type : 'destination' })
                    }
                    this.loadingObject = false
                }
            },
            choosenEndpoints : function (n, o) {
                if (this.choosenEndpoints.source && this.choosenEndpoints.destination) {
                    this.showAuthFlow = true
                }
            }
        }, 
        mounted () {
            this.getAvailableApps()
            this.getUserSubscription()

            const __selectApp = window.localStorage.getItem('__selectApp')
            const __choosenEndpoint__ = window.localStorage.getItem('__selectedEndpoint__')
            const connectionUUID = window.localStorage.getItem('__ConnectionUUID__')
            const authConnection = window.localStorage.getItem('__ConnectionURL__')
            const queryConnection = window.localStorage.getItem('__connectionQuery__')
            const mapObject = window.localStorage.getItem('__mapObject__')
            const stateDirection = window.localStorage.getItem('__stateDirection__')
            const schemaKeys = window.localStorage.getItem('__schemaKeys__')
            const settingTab = window.localStorage.getItem('__settingTab')
            const indexSub = window.localStorage.getItem('_index__p')
            
            
            
            // Get selected app from storage
            if (__selectApp) {
                this.selectApp = JSON.parse(window.localStorage.getItem('__selectApp'))
            } else {
                this.selectApp = []
            }

            if (settingTab) {
                this.tabId = settingTab
            }

            // Update app object if exist in storage
            if (mapObject) {
                const payload = JSON.parse(mapObject)
                this.sourceObjectArray = payload.source
                this.destinationObjectArray = payload.destination
                const data = {}
                data.keys = []
                if (!schemaKeys) {
                    if (this.finalSourceSchemaKey.length < 1) {
                        this.sourceObjectArray.forEach(_ => {
                            this.finalSourceSchemaKey.push(data)
                            this.finalDestinationSchemaKey.push(data)
                        })
                    }
                }
            }

            if (stateDirection) {
                const direction = JSON.parse(stateDirection)
                this.directionsHead = direction
            }

            if (schemaKeys) {
                const schema = JSON.parse(schemaKeys)
                this.finalSourceSchemaKey = schema.sourceKey
                this.finalDestinationSchemaKey = schema.destinationKey
            }

            if (queryConnection) {
                const { status } = this.$route.query
                if (status) {
                    // Check
                    const clickStatus = window.localStorage.getItem('status_click')
                    if (clickStatus === 'source') {
                        const data = JSON.parse(window.localStorage.getItem('__connectionQuery__'))
                        const payload = {...data, source : { access : true, complete : true } }
                        // data.source.complete = true
                        window.localStorage.setItem('__connectionQuery__', JSON.stringify(payload))
                    } else if (clickStatus === 'destination') {
                        const data = JSON.parse(window.localStorage.getItem('__connectionQuery__'))
                        const payload = {...data, destination : { access : true, complete : true } }
                        // data.destination.complete = true
                        window.localStorage.setItem('__connectionQuery__', JSON.stringify(payload))
                    }
                }
                this.queryConnection = JSON.parse(window.localStorage.getItem('__connectionQuery__'))
            }

            if (authConnection) {
                this.authConnectionURL = JSON.parse(window.localStorage.getItem('__ConnectionURL__'))
            }            

            // Get choosen endpoints from storage
            if (__choosenEndpoint__) {
                this.choosenEndpoints = JSON.parse(window.localStorage.getItem('__selectedEndpoint__'))
                this.selectedSourceValue = this.choosenEndpoints.source
                this.selectedDestinationValue = this.choosenEndpoints.destination
            }

            // Get app connectionID from storage
            if (connectionUUID) {
                this.connectionUUID = JSON.parse(connectionUUID)
                const settingTab = window.localStorage.getItem('__settingTab')
                if (parseInt(settingTab) === 3) {
                    this.openTab(4)
                }
            }

            if (indexSub) {
                this.userSubscription = parseInt(indexSub)
            }

            bus.$on('choosen-subscription', e => {
                this.userSubscription = parseInt(e)
                window.localStorage.setItem('_index__p', parseInt(e))
                this.configureAuthURL()
            })

             window.Bus.$emit('sd', e => {

             })

            // Incase page was reload, re-fetch App Object
            if (parseInt(settingTab) > 2 && parseInt(settingTab) < 5) {    
                this.loadingObject = true
                const selectedStorageApp = window.localStorage.getItem('__selectApp')
                if (selectedStorageApp) {
                    this.selectApp = JSON.parse(selectedStorageApp)
                    if (this.selectApp.length > 1) {
                        this.getSelectedAppObject(this.selectApp[0], { type : 'source' })
                        this.getSelectedAppObject(this.selectApp[1], { type : 'destination' })
                    }
                }
                this.loadingObject = false
            }

            // Redirect to tab page
            if (parseInt(settingTab) > 0 && parseInt(settingTab) <= 6) {
                this.tabId = parseInt(settingTab)
                this.openTab(this.tabId)
            } else {
                this.tabId = 1
                this.openTab(this.tabId)
            }
        },
        methods : {
            searchInputTrigger : debounce(function (event) {
                if (event.target.value !== '') {
                    this.filterWebApps(event)
                } else {
                    this.$store.commit('customer/GET_ALL_APPS', null)
                }
            }, 500),
            filterWebApps (event) {
                this.$store.commit('customer/FILTER_APPS', { value : event.target.value })
            },
            onDropFilter (dropResult) {
                this.filters = this.applyDrag(this.filters, dropResult);
            },
            onDropAction (dropResult) {
                this.actionRule = this.applyDragAction(this.actionRule, dropResult);
            },
            applyDrag (arr, dragResult){
                const { removedIndex, addedIndex, payload } = dragResult
                if (removedIndex === null && addedIndex === null) return arr

                const result = [...arr]
                let itemToAdd = payload

                if (removedIndex !== null) {
                    itemToAdd = result.splice(removedIndex, 1)[0]
                }

                if (addedIndex !== null) {
                    result.splice(addedIndex, 0, itemToAdd)
                }
                return result
            },
            applyDragAction (arr, dragResult){
                const { removedIndex, addedIndex, payload } = dragResult
                if (removedIndex === null && addedIndex === null) return arr

                const result = [...arr]
                let itemToAdd = payload

                if (removedIndex !== null) {
                    itemToAdd = result.splice(removedIndex, 1)[0]
                }

                if (addedIndex !== null) {
                    result.splice(addedIndex, 0, itemToAdd)
                }
                return result
            },
            directionSwitcher (index, head) {
                this.$set(this.directionsHead, index, head)
            },
            selectSourceObject (e) {
                if (!this.sourceObjectArray.includes(this.mapSourceObject)) {
                    this.sourceObjectArray.push(this.mapSourceObject)
                    this.directionsHead.push('s')
                    this.mapSourceObject = ''
                }
            },
            selectDestinationObject (e) {
                if (!this.destinationObjectArray.includes(this.mapDestinationObject)) {
                    this.destinationObjectArray.push(this.mapDestinationObject)
                    this.mapDestinationObject = ''
                }
            },
            updateChoosenSourceObject (e, oldVal) {
                const indexOfItem = this.sourceObjectArray.indexOf(oldVal)
                if (!this.sourceObjectArray.includes(e.target.value)) {
                    this.$set(this.sourceObjectArray, indexOfItem, e.target.value)
                } else {
                    this.$set(this.sourceObjectArray, indexOfItem, oldVal)
                }
            },
            updateChoosenDestinationObject (e, oldVal) {
                const indexOfItem = this.destinationObjectArray.indexOf(oldVal)
                if (!this.destinationObjectArray.includes(e.target.value)) {
                    this.$set(this.destinationObjectArray, indexOfItem, e.target.value)
                } else {
                    this.$set(this.destinationObjectArray, indexOfItem, oldVal)
                }
            },
            proceedMapKeys () {
                if (this.sourceObjectArray.length !== this.destinationObjectArray.length) {
                    this.$services.helpers.notification('Incomplete mapping values', 'error', this)
                    return false
                }  else if (this.GET_USER_SUBSCRIPTION.length < 1) {
                    this.$services.helpers.notification('You have no subscription', 'error', this)
                } else {

                    if (this.refresh) {
                        this.connecting = true
                        this.connectionUUID = []
                        const objectArray = {
                            source : this.sourceObjectArray,
                            destination : this.destinationObjectArray
                        }
                        const data = {}
                        data.keys = []
                        this.sourceObjectArray.forEach(_ => {
                            this.finalSourceSchemaKey.push(data)
                            this.finalDestinationSchemaKey.push(data)
                        })
                        window.localStorage.setItem('__mapObject__', JSON.stringify(objectArray))
                        window.localStorage.setItem('__stateDirection__', JSON.stringify(this.directionsHead))
    
                        this.sourceObjectArray.forEach((element, index) => {
                            const payload = {}
                            if (this.directionsHead[index] === 's') {
                                payload.input_app = this.selectApp[0].app_name,
                                payload.input_endpoint = `/${element}/`,
                                payload.output_app = this.selectApp[1].app_name,
                                payload.output_endpoint = `/${this.destinationObjectArray[index]}/`,
                                payload.subscription = this.GET_USER_SUBSCRIPTION[parseInt(this.userSubscription)].id
                            } else {
                                payload.input_app = this.selectApp[1].app_name,
                                payload.input_endpoint = `/${this.destinationObjectArray[index]}/`,
                                payload.output_app = this.selectApp[0].app_name,
                                payload.output_endpoint = `/${element}/`,
                                payload.subscription = this.GET_USER_SUBSCRIPTION[parseInt(this.userSubscription)].id
                            }
    
                                
                            this.$store.dispatch('customer/bindAppConnectionObject', payload)
                            .then(res => {
                                this.$store.commit('customer/UPDATE_CONNECTION_MADE', true)
                                this.connectionUUID.push(res.data.uuid)
    
                                if (this.connectionUUID.length === this.sourceObjectArray.length) {
                                    this.connecting = false
                                    window.localStorage.setItem('__ConnectionUUID__', JSON.stringify(this.connectionUUID))
                                    this.openTab(4)
                                }
                            })
                            .catch(err => {
                                this.connecting = false
                                if (err.response.status === 401) {
                                    this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                                    .then(_ => {
                                        this.proceedMapKeys()
                                    })
                                    .catch(_ => {
                                        this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                        window.Bus.$emit('sign-out')
                                    })
                                }
                            })
                        })
                    } else {
                        this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                        .then(_ => {
                            this.refresh = true
                            setTimeout(() => {
                                this.proceedMapKeys()
                            }, 200)
                        })
                        .catch(_ => {
                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                            window.Bus.$emit('sign-out')
                        })
                    }
                }
            },
            selectSourceSchema (e, index) {
                if (this.finalSourceSchemaKey.length > 0) {
                    const copySourceArray = JSON.parse(JSON.stringify(this.finalSourceSchemaKey[index]))
                    if (!copySourceArray.keys.includes(e.target.value)) {
                        copySourceArray.keys.push(e.target.value)
                        this.$set(this.finalSourceSchemaKey, index, copySourceArray)
                    }
                }
            },
            selectDestinationSchema (e, index) {
                if (this.finalDestinationSchemaKey.length > 0) {
                    const copyDestinationArray = JSON.parse(JSON.stringify(this.finalDestinationSchemaKey[index]))
                    if (!copyDestinationArray.keys.includes(e.target.value)) {
                        copyDestinationArray.keys.push(e.target.value)
                        this.$set(this.finalDestinationSchemaKey, index, copyDestinationArray)
                    }
                }
            },
            removeMapSourceValue (index) {
                this.sourceObjectArray.splice(index, 1)
            },
            removeMapDestinationValue (index) {
                this.destinationObjectArray.splice(index, 1)
            },
            removeSourceSchemaValue (index, keyIndex) {
                this.finalSourceSchemaKey[keyIndex].keys.splice(index, 1)
            },
            removeDestinationSchemaValue (index, keyIndex) {
                this.finalDestinationSchemaKey[keyIndex].keys.splice(index, 1)
            },
            changeFinalSourceValue (e, oldVal, index) {
                const copySourceArray = JSON.parse(JSON.stringify(this.finalSourceSchemaKey[index]))
                const indexOfItem = copySourceArray.keys.indexOf(oldVal)

                if (!copySourceArray.keys.includes(e.target.value)) {
                    copySourceArray.keys[indexOfItem] = e.target.value
                    this.$set(this.finalSourceSchemaKey, index, copySourceArray)
                } else {
                    copySourceArray.keys[indexOfItem] = oldVal
                    this.$set(this.finalSourceSchemaKey, index, copySourceArray)
                }

                // if (!this.finalSourceSchemaKey[index].includes(e.target.value)) {
                //     this.$set(this.finalSourceSchemaKey[index], indexOfItem, e.target.value)
                // } else {
                //     this.$set(this.finalSourceSchemaKey[index], indexOfItem, oldVal)
                // }
            },
            changeFinalDestinationValue (e, oldVal, index) {
                const copyDestinationArray = JSON.parse(JSON.stringify(this.finalDestinationSchemaKey[index]))
                const indexOfItem = copyDestinationArray.keys.indexOf(oldVal)

                if (!copyDestinationArray.keys.includes(e.target.value)) {
                    copyDestinationArray.keys[indexOfItem] = e.target.value
                    this.$set(this.finalDestinationSchemaKey, index, copyDestinationArray)
                } else {
                    copyDestinationArray.keys[indexOfItem] = oldVal
                    this.$set(this.finalDestinationSchemaKey, index, copyDestinationArray)
                }
                // const indexOfItem = this.finalDestinationSchemaKey[index].keys.indexOf(oldVal)
                // if (!this.finalDestinationSchemaKey[index].keys.includes(e.target.value)) {
                //     this.$set(this.finalDestinationSchemaKey, indexOfItem, e.target.value)
                // } else {
                //     this.$set(this.finalDestinationSchemaKey, indexOfItem, oldVal)
                // }
            },
            arrayObjectSource (objectValue) {
                if (this.mapSourceResponse.length > 0) {
                    const filter = this.mapSourceResponse.filter(item => item.endpoint === objectValue)
                    if (filter.length > 0) {
                        if (filter[0].schema.properties) {
                            return JSON.parse(JSON.stringify(Object.keys(filter[0].schema.properties)))
                        } else {
                            return JSON.parse(JSON.stringify(Object.keys(filter[0].schema[objectValue].properties)))
                        }
                    }
                }
            },
            arrayObjectDestination (objectValue) {
                if (this.mapDestinationResponse.length > 0) {
                    const filter = this.mapDestinationResponse.filter(item => item.endpoint === objectValue)
                    if (filter.length > 0) {
                        if (filter[0].schema.properties) {
                            return JSON.parse(JSON.stringify(Object.keys(filter[0].schema.properties)))
                        } else {
                            return JSON.parse(JSON.stringify(Object.keys(filter[0].schema[objectValue].properties)))
                        }
                    }
                }
            },
            // choosenObjectSchema (objectValue, location) {
            //     if (location === 'source') {
            //         if (this.mapSourceResponse.length > 0) {
            //             return Object.keys(this.mapSourceResponse.filter(item => item.endpoint === objectValue)[0].schema)
            //         }
            //     } else {
            //         if (this.mapDestinationResponse.length > 0) {
            //             console.log(Object.keys(this.mapDestinationResponse.filter(item => item.endpoint === objectValue)[0].schema))
            //             return Object.keys(this.mapDestinationResponse.filter(item => item.endpoint === objectValue)[0].schema)
            //         }
            //     }
            // },
            proceedLastTab () {
                let returnValue = false
                this.finalSourceSchemaKey.forEach((_, index) => {
                    if (this.finalSourceSchemaKey[index].keys.length < 1 && this.finalDestinationSchemaKey[index].keys.length < 1) {
                        returnValue = true
                        return true
                    } else if (this.finalSourceSchemaKey[index].keys.length !== this.finalDestinationSchemaKey[index].keys.length) {
                        returnValue = true
                        // console.log('Not equaul here')
                        return true
                    }
                })

                if (returnValue) {
                    this.$services.helpers.notification('Incomplete mapping values', 'error', this)
                } else {
                    // Save schema in storage
                    const payload = {
                        sourceKey : this.finalSourceSchemaKey,
                        destinationKey : this.finalDestinationSchemaKey
                    }
                    window.localStorage.setItem('__schemaKeys__', JSON.stringify(payload))
                    this.openTab(5)
                }
            },
            checkApp (app) {
                const checkExistence = this.selectApp.filter(item => item.app_name === app.app_name)
                if (checkExistence.length > 0) {
                    return true
                } else {
                    return false     
                }
            },
            getAvailableApps () {
                this.loading = true
                this.$store.dispatch('customer/getAvailableApps')
                .then(_ => {
                    this.loading = false
                })
                .catch(err => { 
                    this.loading = false
                    if (err.response.status === 401) {
                        this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                        .then(_ => {
                            this.getAvailableApps()
                        })
                        .catch(_ => {
                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                            window.Bus.$emit('sign-out')
                        })
                    }
                })
            },
            getUserSubscription () {
                this.$store.dispatch('customer/getUserSubscription')
                .then(_ => {})
                .catch(err => {
                    if (err.response.status === 401) {
                        this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                        .then(_ => {
                            this.getUserSubscription()
                        })
                        .catch(_ => {
                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                            window.Bus.$emit('sign-out')
                        })
                    }
                })
            },
            chooseApp (app) {
                if (this.selectApp.length < 2) {
                    const checkExistence = this.selectApp.filter(item => item.app_name === app.app_name)
                    if (checkExistence.length > 0) {
                        const sortApp = this.selectApp.filter(item => item.app_name !== app.app_name)
                        this.selectApp = sortApp
                    } else {
                        this.selectApp.push(app)
                    }
                } else {
                    const checkExistence = this.selectApp.filter(item => item.app_name === app.app_name)
                    if (checkExistence.length > 0) {
                        const sortApp = this.selectApp.filter(item => item.app_name !== app.app_name)
                        this.selectApp = sortApp
                    }
                }
                window.localStorage.setItem('__selectApp', JSON.stringify(this.selectApp))
            },
            onMapSourceChange (e) {
                this.saveEndpoints('source', { source : e.target.value })
                const filter = this.mapObjectSource.filter(item => item.endpoint === this.selectedSourceObject)
                if (filter.length > 0) {
                    this.mapObjectSource = filter
                    this.mapObjectSourceSchema = filter[0].schema
                }
            },
            onMapDestinationChange (e) {
                this.saveEndpoints('destination', { destination: e.target.value })
                const filter = this.mapObjectDestination.filter(item => item.endpoint === this.selectedDestinationObject)
                if (filter.length > 0) {
                    this.mapObjectDestination = filter
                    this.mapObjectDestinationSchema = filter[0].schema
                }
            },
            saveEndpoints (location, value) {
                const storage = window.localStorage.getItem('__selectedEndpoint__')
                if (storage) {
                    const data = JSON.parse(storage)
                    const payload = {...data }
                    payload[location] = Object.values(value)[0]
                    window.localStorage.setItem('__selectedEndpoint__', JSON.stringify(payload))
                    this.choosenEndpoints = JSON.parse(window.localStorage.getItem('__selectedEndpoint__'))
                } else {
                    window.localStorage.setItem('__selectedEndpoint__', JSON.stringify(value))
                }
            },
            openSubscription () {
                bus.$emit('select-subscription')
            },
            configureAuthURL () {
                if (this.GET_USER_SUBSCRIPTION[parseInt(this.userSubscription)]) {
                    const payload = {
                        sourceURL : process.env.VUE_APP_CONNECTION_URL + 'connect/' + this.GET_USER_SUBSCRIPTION[parseInt(this.userSubscription)].uuid + '?app=' + this.selectApp[0].app_name + '&next=' + window.location.origin + window.location.pathname,
                        destinationURL : process.env.VUE_APP_CONNECTION_URL + 'connect/' + this.GET_USER_SUBSCRIPTION[parseInt(this.userSubscription)].uuid + '?app=' + this.selectApp[1].app_name + '&next=' + window.location.origin + window.location.pathname
                    }
                    window.localStorage.setItem('__ConnectionURL__', JSON.stringify(payload))
                    this.authConnectionURL = payload
                    // this.openTab(2)
                    this.verifyConnectionExistence()
                } else {
                    this.$services.helpers.notification(this.$t('selectSubscription'), 'error', this)
                }
            },
            verifyConnectionExistence () {
                if (this.GET_USER_SUBSCRIPTION[parseInt(this.userSubscription)].authenticatedapps.includes(this.selectApp[0].app_name) &&
                this.GET_USER_SUBSCRIPTION[parseInt(this.userSubscription)].authenticatedapps.includes(this.selectApp[1].app_name)) {
                    this.getSelectedAppObject(this.selectApp[0], { type : 'source' })
                    this.getSelectedAppObject(this.selectApp[1], { type : 'destination' })
                    this.openTab(3)
                } else {
                    this.openTab(2)
                }
            },
            connectSourceApp () {
                const connection = window.localStorage.getItem('__connectionQuery__')
                if (connection) {
                    const data = JSON.parse(connection)
                    const payload = {...data }
                    payload.source.access = true
                    payload.source.complete = false
                    window.localStorage.setItem('__connectionQuery__', JSON.stringify(payload))
                } else {
                    const payload = {
                        source : { access : true, complete : false },
                        destination : { access : false, complete : false }
                    }
                    window.localStorage.setItem('__connectionQuery__', JSON.stringify(payload))
                }
                window.localStorage.setItem('status_click', 'source')
                window.open(this.authConnectionURL.sourceURL, '_self')
            },
            connectDestinationApp () {
                const connection = window.localStorage.getItem('__connectionQuery__')
                if (connection) {
                    const data = JSON.parse(connection)
                    const payload = {...data }
                    payload.destination.access = true
                    payload.destination.complete = false
                    window.localStorage.setItem('__connectionQuery__', JSON.stringify(payload))
                } else {
                    const payload = {
                        source : { access : false, complete : false },
                        destination : { access : true, complete : false }
                    }
                    window.localStorage.setItem('__connectionQuery__', JSON.stringify(payload))
                }
                window.localStorage.setItem('status_click', 'destination')
                window.open(this.authConnectionURL.destinationURL, '_self')
            },
            async getSelectedAppObject(data, utility = null) {
                if (utility) {
                    const payload = {
                        query : `?app=${data.app_name}`
                    }
                    this.$store.dispatch('customer/getSelectedAppObject', payload)
                    .then(res => {
                        const { type } = utility
                        if (type === 'source') {
                            if (this.choosenEndpoints.source) {
                                const filter = res.data.data.filter(item => item.endpoint === this.choosenEndpoints.source)
                                if (filter.length > 0) {
                                    this.mapObjectSource = filter
                                    this.mapObjectSourceSchema = filter[0].schema
                                }
                            } else {
                                this.mapObjectSource = res.data.data
                                this.mapObjectSourceSchema = res.data.data[0].schema
                            }
                            this.mapSourceResponse = res.data.data
                        } else {
                            if (this.choosenEndpoints.destination) {
                                const filter = res.data.data.filter(item => item.endpoint === this.choosenEndpoints.destination)
                                if (filter.length > 0) {
                                    this.mapObjectDestination = filter
                                    this.mapObjectDestinationSchema = filter[0].schema
                                }
                            } else {
                                this.mapObjectDestination = res.data.data
                                this.mapObjectDestinationSchema = res.data.data[0].schema
                            }
                            this.mapDestinationResponse = res.data.data
                        }
                    })
                    .catch(err => {
                        if (err.response.status === 401) {
                            this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                            .then(_ => {
                                this.getSelectedAppObject(data, utility)
                            })
                            .catch(_ => {
                                this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                window.Bus.$emit('sign-out')
                            })
                        }
                    })
                }
            },
            proceedTestOrder () {
                this.$store.commit('customer/RESET_CONNECTION_INDICATOR', {})
                this.percentage = 100
                this.tabId = 6
                window.localStorage.setItem('__settingTab', 6)
            },
            performTestSubscription () {
                const self = this
                this.runningTest = true
                this.triggerKeys.forEach((element, index) => {
                    this.$store.dispatch('customer/triggerConnection', element)
                    .then(res => {
                        this.$store.commit('customer/COMPLETE_SINGLE_CONNECTION', { id : index })

                        if (index + 1 === this.triggerKeys.length) {
                            this.runningTest = false
                        }
                    })
                    .catch(err => {
                        if (err.response.status === 401) {
                            this.$store.commit('customer/FAIL_SINGLE_CONNECTION', { id : index })
                            this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                            .then(_ => {
                                self.performTestSubscription()
                            })
                            .catch(_ => {
                                this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                window.Bus.$emit('sign-out')
                            })
                        }
                    })
                });
            },
            addFilter () {
                if (this.singleFilters.includes(this.filterForm.allow)) {
                    const data = this.filters.filter(item => item.allow === this.filterForm.allow)

                    if (data.length > 0) {
                        const filterExistence = data.filter(item => parseInt(item.obj) === parseInt(parseInt(this.filterObject)))
                        if (filterExistence.length > 0) {
                            this.$services.helpers.notification(this.$t('filterExistence'), 'error', this)
                            return false
                        } else {
                            const objectFilter = this.filterObject
                            this.filters.push({ allow : this.filterForm.allow, obj : objectFilter })
                            this.filterForm.allow = ''
                        }
                    } else {
                        const objectFilter = this.filterObject
                        this.filters.push({ allow : this.filterForm.allow, obj : objectFilter })
                        this.filterForm.allow = ''
                    }
                } else {
                    if (this.filterForm.allow !== '' && this.filterForm.key !== '' && this.filterForm.value !== '') {
                        const objectFilter = this.filterObject
                        
                        this.filters.push({ 
                            obj : objectFilter,
                            allow : this.filterForm.allow,
                            key : this.filterForm.key,
                            value : this.filterForm.value,
                            from : 'direct'
                        })
                        Object.keys(this.filterForm).forEach(element => {
                            this.filterForm[element] = ''                        
                        })
                    }
                }
                this.filterObject = ''
            },
            removeFilter (indexOfItem) {
                const data = this.filters.filter(item => this.filters.indexOf(item) !== indexOfItem)
                this.filters = data
            },
            addActionRule () {
                if (this.actionForm.type === 'Condition') {
                    const objectAction = this.actionObject

                    const payload =  {
                        obj : objectAction,
                        type : this.actionForm.type,
                        app_name : this.actionForm.name,
                        value : this.actionForm.value,
                        key : this.actionForm.key,
                    }
                    this.actionRule.push(payload)
                } else {
                    if (this.actionForm.type === 'Transformation') {
                        // Check if transformation exist
                        const filterExistingValue = this.actionRule.filter(item => parseInt(item.obj) === parseInt(this.actionObject) && item.type === this.actionForm.type)
                        if (filterExistingValue.length < 1) {
                            this.actionRule.push({ obj : this.actionObject, type : this.actionForm.type })
                        } else {
                            this.$services.helpers.notification('Key value already exist', 'error', this)
                        }
                    } else {
                        const objectAction = this.actionObject
    
                        this.actionRule.push({
                            obj : objectAction,
                            type : this.actionForm.type,
                            app_name : this.actionForm.name,
                            value : this.actionForm.value,
                            key : this.actionForm.key,
                            to : { type : 'output_object', key : this.actionForm.to }
                        })
                    }
                }
                this.actionObject = ''
                Object.keys(this.actionForm).forEach(element => {
                    this.actionForm[element] = ''                        
                })
            },
            removeActionRule (indexOfItem) {
                const data = this.actionRule.filter(item => this.actionRule.indexOf(item) !== indexOfItem)
                this.actionRule = data
            },
            addTrigger () {
                if (this.trigger.status === '') {
                    this.$services.helpers.notification('Add trigger actions', 'error', this)
                } else {
                    const objectInterval = this.intervalObject

                    const payload = {
                        obj : objectInterval,
                        status : this.trigger.status,
                        every : this.trigger.every,
                        period : this.trigger.period
                    }
                    this.triggerArray.push(payload)
                    Object.keys(this.trigger).forEach(element => {
                        this.trigger[element] = ''  
                    })
                    this.intervalObject = ''
                }
            },
            removeTrigger (indexOfItem) {
                const data = this.triggerArray.filter(item => this.triggerArray.indexOf(item) !== indexOfItem)
                this.triggerArray = data
            },
            addNotify () {
                if (this.notify.action === '' || this.notify.type === '' || this.notify.notify === '') {
                    this.$services.helpers.notification('Add notification rule', 'error', this)
                } else if (this.notify.channel === 'mail' && !this.$services.helpers.validateEmailAddress(this.notify.receiver)) {
                    this.$services.helpers.notification('Enter valid email receiver', 'error', this)
                } else {
                    const objectNotify = this.notifyObject

                    const payload = {
                        obj : objectNotify,
                        action : '',
                        error : {
                            action : '',
                            via : '',
                            to : ''
                        }, 
                        success : {
                            action : '',
                            via : '',
                            to : ''
                        },
                    }
                    payload.action = this.notify.action
                    if (this.notify.type === 'on_error') {
                        payload.error.action = this.notify.notify ? 'notify' : null
                        payload.error.via = this.notify.channel
                        payload.error.to = this.notify.receiver
                    } else {
                        payload.success.action = this.notify.notify ? 'notify' : null
                        payload.success.via = this.notify.channel
                        payload.success.to = this.notify.receiver
                    }
                    
                    this.notificationRule.push(payload)
                    Object.keys(this.notify).forEach(element => {
                        this.notify[element] = ''  
                    })
                    this.notifyObject = ''
                }
            },
            removeNotificationRule (indexOfItem) {
                const data = this.notificationRule.filter(item => this.notificationRule.indexOf(item) !== indexOfItem)
                this.notificationRule = data
            },
            addSyncUrl () {
                if (this.syncObject === '' || this.syncURLForm.endpoint === '' || this.syncURLForm.successFailed === '') {
                    return
                } else {
                    const objectId = this.syncObject
                    const payload = {
                        obj : objectId,
                        endpoint : this.syncURLForm.endpoint,
                        stop_if_fail : this.syncURLForm.successFailed,
                    }
                    this.syncURLArray.push(payload)
                    Object.keys(this.syncURLForm).forEach(element => {
                        this.syncURLForm[element] = ''  
                    })
                    this.syncObject = ''
                }
            },
            removeSyncURL (indexOfItem) {
                const data = this.syncURLArray.filter(item => this.syncURLArray.indexOf(item) !== indexOfItem)
                this.syncURLArray = data
            },
            validateFilter () {
                let error = false
                this.sourceObjectArray.forEach((_, index) => {
                    const filter = this.filters.filter(item => parseInt(item.obj) === parseInt(index))
                    if (filter.length < 1) {
                        error = true
                    }
                })
                return error
            },
            validateAction () {
                let error = false
                this.sourceObjectArray.forEach((_, index) => {
                    const filter = this.actionRule.filter(item => parseInt(item.obj) === parseInt(index))
                    if (filter.length < 1) {
                        error = true
                    }
                })
                return error
            },
            createRuleDefinition () {
                // if (this.filters.length < 1) {
                //     this.$services.helpers.notification('Please add filter rule', 'error', this)
                // } else if (this.validateFilter()) {
                //     this.$services.helpers.notification('Please add filter rule for all connection objects', 'error', this)
                // } else if (this.actionRule.length < 1) {
                //     this.$services.helpers.notification('Configure action types', 'error', this)
                // } else if (this.validateAction()) {
                //     this.$services.helpers.notification('Please add action rules for all connection objects', 'error', this)
                // } else if (this.notificationRule.length < 1) {
                //     this.$services.helpers.notification('Configure notification rule', 'error', this)
                // } else if (this.triggerArray.length < 1) {
                //     this.$services.helpers.notification('Invalid trigger configuration', 'error', this)
                // } else {
                    const connectionArray = []
                    const self = this

                    this.sourceObjectArray.forEach((element, index) => {
                        const connectionData = {}
                        if (this.directionsHead[index] === 's') {
                            connectionData.unique = index,
                            connectionData.connectionName = this.$services.helpers.capitalizeEachString(element + ' -- ' + this.destinationObjectArray[index]),
                            connectionData.status = true,element
                            connectionData.complete = false,
                            connectionData.failed = false
                        } else {
                            connectionData.unique = index,
                            connectionData.connectionName = this.$services.helpers.capitalizeEachString(this.destinationObjectArray[index] + ' -- ' + element),
                            connectionData.status = true,
                            connectionData.complete = false,
                            connectionData.failed = false
                        }
                        connectionArray.push(connectionData)
                    })
                    
                    this.$store.commit('customer/ADD_CONNECTIONS', connectionArray)
                    window.Bus.$emit('open-connection-progress')

                    this.sourceObjectArray.forEach((element, index) => {
                        let payload = {
                            definition : {
                                actions : [],
                                finish : {},
                                depends : [],
                            },
                            triggers : []
                        }
                        
                        const actions = []
                        const finish = {
                            do : {},
                            on_error : {},
                            on_success : {},
                        }

                        // Get respective connection filters and add filter
                        const connectionFilter = this.filters.filter(item => parseInt(item.obj) === parseInt(index))
                        if (connectionFilter.length > 0) {
                            connectionFilter.forEach(element => {
                                delete element.obj
                            })
                            payload.definition.filters = connectionFilter
                        }


                        // Add corresponding schema key actions
                        let sourceSchemaKey = null
                        let destinationSchemaKey = null
                        if (this.directionsHead[index] === 's') {
                            sourceSchemaKey = this.finalDestinationSchemaKey[index]
                            destinationSchemaKey = this.finalSourceSchemaKey[index]
                        } else {
                            sourceSchemaKey = this.finalSourceSchemaKey[index]
                            destinationSchemaKey = this.finalDestinationSchemaKey[index]
                        }

                        sourceSchemaKey.keys.forEach((item, i) => {
                            const mapObject = {}
                            mapObject.type = 'Map'
                            mapObject.src_key = item
                            mapObject.dst_key = destinationSchemaKey.keys[i]

                            actions.push(mapObject) // Push to actions array
                        })

                        
                        // Add actions rule
                        const filterAction = this.actionRule.filter(item => parseInt(item.obj) === parseInt(index))
                        if (filterAction.length > 0) {
                            filterAction.forEach(element => {
                                delete element.obj
                                actions.push(element)
                            })
                        }

                        // Push all actions into root object
                        payload.definition.actions = actions

                        // Check for endpoints urls and add to main payload
                        const syncUrl = this.syncURLArray.filter(item => parseInt(item.obj) === parseInt(index))
                        if (syncUrl.length > 0) {
                            syncUrl.forEach(element => {
                                delete element.obj
                            })
                            payload.definition.depends = syncUrl
                        }

                        // Filter notification rule
                        const filterNotify = this.notificationRule.filter(item => parseInt(item.obj) === parseInt(index))
                        if (filterNotify.length > 0) {
                            // Add on finish rule equivalent -->(Notification rule)
                            finish.do.action = filterNotify[0].action
                            finish.on_error.action = filterNotify[0].error.action
                            finish.on_error.via = filterNotify[0].error.via || null
                            finish.on_error.to = filterNotify[0].error.via === 'mail' ? filterNotify[0].error.to : null
                            finish.on_success.action = filterNotify[0].success.action
                            finish.on_success.via = filterNotify[0].success.via || null
                            finish.on_success.to = filterNotify[0].success.via === 'mail' ? filterNotify[0].success.to : null
                        }

                        // Add finish rule to main payload
                        payload['definition']['finish'] = finish

                        // Add on_error constant
                        payload.definition.on_error = 'continue'

                        // Configure trigger equivalent-->(Trigger Array)
                        const filterTrigger = this.triggerArray.filter(item => parseInt(item.obj) === parseInt(index))
                        if (filterTrigger.length > 0) {
                            filterTrigger.forEach(element => {
                                const data = {
                                    definition : { check : {}},
                                    webhook : {active: 'True', 'key': 'None'} 
                                }
                                if (element.status) {
                                    data.definition.check.active = element.status
                                    data.definition.check.every = element.every
                                    data.definition.check.period = element.period
                                } else {
                                    data.definition.check.active = element.status
                                    data.definition.check.every = null
                                    data.definition.check.period = null
                                }
                                payload.triggers.push(data)
                            })
                        }

                        this.creating = true
                        // Complete all data and submit to backend
                        this.$store.dispatch('customer/addDefinitionRule', { id : this.connectionUUID[index], payload })
                        .then(res => {
                            // this.creating = true
                            this.$store.commit('customer/COMPLETE_SINGLE_CONNECTION', { id : index })
                            
                            // Store trigger keys for testing
                            if (res.data.triggers.length > 0) {
                                this.triggerKeys.push(res.data.triggers[0].key)
                            }
                            
                            if (index + 1 === this.sourceObjectArray.length) {
                                this.creating = false
                                this.$store.commit('customer/COMPLETE_PROCESSING')
                                
                                if (res.data.triggers.length < 1) {
                                    this.clearData()
                                }
                            }
                        })
                        .catch(err => {
                            console.log(err)
                            this.creating = false
                            if (err.response.status === 401) {
                                this.$store.commit('customer/FAIL_SINGLE_CONNECTION', { id : index })
                                this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                                .then(_ => {
                                    self.createRuleDefinition()
                                })
                                .catch(_ => {
                                    this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                    window.Bus.$emit('sign-out')
                                })
                            }
                            if (err.response.status === 400) {
                                this.$store.commit('customer/FAIL_SINGLE_CONNECTION', { id : index })
                                this.$store.commit('customer/COMPLETE_PROCESSING')
                            }
                        })
                    })
                // }
            },
            clearData () {
                this.openTab(1)
                this.subTab = 1
                this.selectApp = []
                this.triggerKeys = []
                this.sourceObjectArray = []
                this.destinationObjectArray = []
                this.finalSourceSchemaKey = []
                this.finalDestinationSchemaKey = []
                this.connectionUUID = []
                this.authConnectionURL = {}
                this.directionsHead = []
                this.queryConnection.source.access = false
                this.queryConnection.source.complete = false
                this.queryConnection.destination.access = false
                this.queryConnection.destination.complete = false
                this.choosenEndpoints.source = ''
                this.choosenEndpoints.destination = ''

                
                // Empty array lists
                this.filters = []
                this.actionForm = []
                this.notificationRule = []
                this.syncURLArray = []
                this.triggerArray = []

                // Remove all storage data
                window.localStorage.removeItem('__selectApp')
                window.localStorage.removeItem('__selectedEndpoint__')
                window.localStorage.removeItem('__ConnectionUUID__')
                window.localStorage.removeItem('__ConnectionURL__')
                window.localStorage.removeItem('__connectionQuery__')
                window.localStorage.removeItem('__mapObject__')
                window.localStorage.removeItem('__stateDirection__')
                window.localStorage.removeItem('__schemaKeys__')
            },
            switchSubTab (number) {
                this.subTab = number
            },
            openTab (id) {
                if (id === 1) { 
                    this.percentage = 15
                    this.tabId = id
                    window.localStorage.setItem('__settingTab', id)
                } else if (id === 2) {
                    this.percentage = 35
                    this.tabId = id
                    window.localStorage.setItem('__settingTab', id)
                } else if (id === 3) {
                    this.percentage = 50
                    this.tabId = id
                    window.localStorage.setItem('__settingTab', id)
                } else if (id === 4) {
                    this.percentage = 70
                    this.tabId = id
                    window.localStorage.setItem('__settingTab', id)
                } else if (id === 5) {
                    this.percentage = 87
                    this.tabId = id
                    window.localStorage.setItem('__settingTab', id)
                } else {
                    this.percentage = 100
                    this.tabId = id
                    window.localStorage.setItem('__settingTab', id)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";
.icon-progress{
    background: url('./../../assets/images/icon-progress.svg');
    background-repeat: no-repeat;
    // width: 100%;
    background-size: contain;
}
.icon-prog{
    font-size: 6rem;
    background: url('https://www.talkwalker.com/images/2020/blog-headers/image-analysis.png');
    background-position: bottom;
    -webkit-background-clip:text;
    color: transparent;
}
img {
    display: block;
}
.progress-img{
    max-width: 100% !important;
}
.thumbnail {
    position: relative;
    display: inline-block;
}

.caption {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50% );
    text-align: center;
    color: white;
    font-weight: bold;
}
.table th{
    text-align: left !important;     
    // background: rgba(223, 224, 235, 0.5);
    padding: 0.4rem 0.55rem !important;
    // color: #000000 !important;
}
.table-striped tbody:nth-of-type(2n+1) {
    // background-color: rgba(128, 69, 69, 0.03);
}
.table tbody tr > td{
    // padding: 0.1rem 0.55rem !important;
    font-size: 12px !important;
    text-align: left;
    color: #6F6F6F;
}
.smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper{
    display: revert !important;
}
.k-progress-outer{
    padding-right: unset !important;
}   
</style>
